package com.picme.views.share

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.InviteCode
import kotlinx.coroutines.launch


data class ViewInviteInfo(
    val inviteCode: InviteCode,
    val permission: InviteType,
    val link: String,
)

fun ViewWriter.invites(collectionId: CollectionId, invite: Property<ViewInviteInfo?>) {
    card - stack {
        sizeConstraints(minHeight = 6.5.rem) - col {
            row {
                spacing = 0.dp
                space()
                h6 { content = "Permissions:" }
            }
            val qrCodes = Property<List<ViewInviteInfo>>(listOf())
            val loading = Property(true)
            val error = Property("")
            launch {
                try {
                    error set ""
                    val coll = session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection
                    qrCodes.value = getQrCodesForCollection(coll).reversed()
                    invite.value = qrCodes().find { it.inviteCode.name == "Share Collection" }
                } catch (e: Exception) {
                    e.printStackTrace2()
                    error set "Error loading Invitations."
                } finally {
                    loading set false
                }
            }
            padded - col {
                ::exists { loading() }
                space();space();space()
                centered - activityIndicator()
                sizeConstraints(height = 1.rem) - space()
                centered - subtext("Loading Invitations...")
            }
            centered - padded - text {
                exists = true; ::exists { qrCodes().isEmpty() && !loading() && error() == "" }
                ::content {
                    "There are no invitations"
                }
            }

            centered - danger - text {
                ::exists { error() != "" }
                ::content { error() }
            }
            col {
                spacing = 0.25.rem
                forEachUpdating(qrCodes) { qrInfo ->
                    unpadded - col {
                        unpadded - row {
                            unpadded - button {
                                icon {
                                    reactiveSuspending {
                                        source = if(invite() == qrInfo.state.raw) {
                                            PIcon.radioButtonChecked
                                        } else {
                                            PIcon.radioButtonUnchecked
                                        }
                                    }
                                }
                                onClick {
                                    invite.value = qrInfo.state.raw
                                }
                            }
                            centered - FadedForeground.onNext - text { ::content { when (qrInfo().inviteCode.name) {
                                "Request Photos" -> "Upload Photos Only"
                                "Share Collection" -> "Upload Photos & View Collection"
                                else -> ""
                            } }}
                        }
                    }
                }
            }
        }
    }
}

//class Invites(
//    val collectionId: CollectionId,
//    val navToInvite: suspend (invite: ViewInviteInfo) -> Unit
//) : Screen {
//    override fun ViewWriter.render() {
//        expanding - sizeConstraints(minHeight = 12.rem) - col {
//            val qrCodes = Property<List<ViewInviteInfo>>(listOf())
//            val loading = Property(true)
//            val error = Property("")
//            launch {
//                try {
//                    error set ""
//                    val coll = session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection
//                    qrCodes set getQrCodesForCollection(coll)
//                } catch (e: Exception) {
//                    e.printStackTrace2()
//                    error set "Error loading Invitations."
//                } finally {
//                    loading set false
//                }
//            }
//            padded - col {
//                ::exists { loading() }
//                space(); space();space()
//                centered - activityIndicator()
//                sizeConstraints(height = 1.rem) - space()
//                centered - subtext("Loading Invitations...")
//            }
//            centered - padded - text {
//                ::exists { qrCodes().isEmpty() && !loading() && error() == "" }
//                ::content {
//                    "There are no invitations"
//                }
//            }
//
//            centered - danger - text {
//                ::exists { error() != "" }
//                ::content { error() }
//            }
//            expanding - stack {
//                expanding - recyclerView {
//                    ::exists { qrCodes().isNotEmpty() }
//                    children(qrCodes) { qrInfo ->
//                        col {
//                            button {
//                                onClick {
//                                    navToInvite(qrInfo())
//                                }
//                                row {
//                                    atTopStart - compact - compact - padded - ThemeDerivation {
//                                        it.copy(iconOverride = Color.black).withoutBack
//                                    }.onNext - icon {
//                                        source = PIcon.qrcode
//                                    }
//                                    noSpacing - centered - col {
//                                        themeChoice = FadedForeground
//                                        row {
//                                            atBottom - h4 { ::content { qrInfo().inviteCode.name } }
//                                            sizeConstraints(width = 1.rem) - space()
//                                            centered - danger - text {
//                                                themeChoice = HoverDanger
//                                                ::exists { qrInfo().inviteCode.temporarilyDisabled }
//                                                content = "Paused"
//                                            }
//                                        }
//                                        text {
//                                            ::content { qrInfo().permission.readable() }
//                                        }
//                                    }
//
//                                    expanding - space {}
//                                    centered - icon { source = PIcon.chevronright }
//                                }
//                            }
//                            separatorTheme - separator {
//                                ::exists { qrCodes().indexOf(qrInfo()) < qrCodes().size - 1 }
//                            }
//                        }
//                    }
//                }
//            }
//            space {}
//        }
//    }
//}

@ViewModifierDsl3
val ViewWriter.noSpacing: ViewWrapper
    get() = ThemeDerivation { it.copy(spacing = 0.dp).withBack }.onNext
