package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.setStatusBarColor
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.*
import com.picme.*
import com.picme.actuals.disableContextMenu
import kotlin.math.round


data class RecyclableInfo(
    val id: String,
    val thumbnail: ImageSource,
    val isAd: Boolean,
    val mimeType: String
)

fun ViewWriter.imageRecyclerView(
    colImages: Readable<List<RecyclableInfo>>,
    selectedImages: SelectItems,
    setup: RecyclerView.() -> Unit,
    navToImage: (suspend (image: Readable<RecyclableInfo>) -> Page)? = null
) {
    expanding - recyclerView {
        spacing = 2.dp
        log = null
        outerStack.exists = false
        outerStack::exists { colImages().isNotEmpty() }
        overdraw = 200.0

        placer = RecyclerViewPlacerVerticalGrid(4, 1.0)
        val gridSize = Property(GridSize.Small)
        reactiveSuspending { gridSize.set(getGridSize()) }
        reactive {
            placer = RecyclerViewPlacerVerticalGrid(round(AppState.windowInfo().width.px / (if (gridSize() == GridSize.Large) 220 else 110).dp.px).toInt(), ratio = 1.0)
        }

        rendererSet = object: RecyclerViewRendererSet<RecyclableInfo, String> {
            override fun id(item: RecyclableInfo): String = item.id
            val main = object: RecyclerViewRenderer<RecyclableInfo> {
                override fun render(
                    viewWriter: ViewWriter,
                    data: Readable<RecyclableInfo>,
                    index: Readable<Int>
                ): ViewModifiable = with(viewWriter) {
                    val img = data
                    unpadded - card - button {
                        spacing = 0.rem
                        stack {
                            tweakTheme { it.copy(cornerRadii = CornerRadii.Constant(0.dp)) } - image {
                                showLoadingIndicator = false
                                // TODO: Debug why this is required on iOS
                                // Otherwise images display at about a third of the expected size. Is it an image caching thing?
                                // Is the thumbnail really just at a resolution that naturally appears small on high-res iOS screens?
                                naturalSize = true
                                disableContextMenu()
                                ::source { img().thumbnail }; scaleType = ImageScaleType.Crop
                            }
                            gravity(Align.Start, Align.Start) - sizeConstraints(
                                width = 2.rem,
                                height = 2.rem
                            ) - unpadded - image {
                                ::exists { selectedImages.selected().contains(img()) }
                                source = Resources.checkCircleSelected
                            }
                            stack {
                                ::exists { img().mimeType.contains("video") }

                                centered - sizeConstraints(
                                    width = 3.rem,
                                    height = 3.rem
                                ) - unpadded - icon {
                                    source = PIcon.playIcon
                                    themeChoice += ThemeDerivation { it.copy(iconOverride = Color.white ).withoutBack }
                                }
                                themeChoice += ThemeDerivation {
                                    it.copy(background = Color.black.withAlpha(0.5f)).withBack
                                }
                            }
                        }

                        onClick {
                            if (selectedImages.isSelecting()) {
                                if (selectedImages.selected().contains(img())) {
                                    selectedImages.removeItem(img())
                                } else {
                                    selectedImages.addItem(img())
                                }
                            } else {
                                navToImage?.let { dialogScreenNavigator.navigate(it(img)) }
                            }
                        }
                    }
                }
            }
            override fun renderer(item: RecyclableInfo): RecyclerViewRenderer<RecyclableInfo> = main
        }
        reactive {
            val d = colImages()
            new.data = object: RecyclerViewData<RecyclableInfo, String> {
                override val range: IntRange get() = d.indices
                override fun get(index: Int): RecyclableInfo = d[index]
            }
        }
        setup()
    }
}
