package com.picme.views

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.stack
import com.picme.UnauthScreen
import com.picme.currentCollection
import com.picme.sdk2.toSafeEncoded
import com.picme.session
import com.picme.sessionRefreshToken
import kotlinx.coroutines.launch


@Routable("/")
class LandingScreen : Page, UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {
        suspend fun handleWebFlow(isLoggedIn: Boolean) {
            if (isLoggedIn) {
                val firstCollection = session()?.collection2?.listCollectionsLive()?.all()?.firstOrNull()
                if (firstCollection != null) {
                    screenNavigator.replace(CollectionDetail(firstCollection.collection.collectionId.raw.toSafeEncoded()))
                } else {
                    screenNavigator.replace(CollectionLanding)
                }
            } else {
                screenNavigator.replace(LoginOrSignUp())
            }
        }

        suspend fun handleMobileFlow(isLoggedIn: Boolean) {
            if (!WelcomeTOSLanding.acknowledged()) {
                screenNavigator.replace(WelcomeTOSLanding)
            } else if (isLoggedIn) {
                if (currentCollection() != null) {
                    currentCollection()?.collectionId?.raw?.let { CollectionDetail(it.toSafeEncoded()) }
                        ?.let { screenNavigator.replace(it) }
                } else {
                    val firstCollection = session()?.collection2?.listCollectionsLive()?.all()?.firstOrNull()
                    if (firstCollection != null) {
                        screenNavigator.replace(CollectionDetail(firstCollection.collection.collectionId.raw.toSafeEncoded()))
                    } else {
                        screenNavigator.replace(CollectionLanding)
                    }
                }
            } else {
                screenNavigator.replace(CollectionLanding)
            }
        }

        suspend fun handleDesktopFlow(isLoggedIn: Boolean) {
            throw RuntimeException("Desktop not yet supported")
        }

        launch {
            val loggedIn = !sessionRefreshToken().isNullOrEmpty()
            val platform = Platform.current
            when (platform) {
                Platform.Web -> handleWebFlow(isLoggedIn = loggedIn)
                Platform.iOS, Platform.Android -> handleMobileFlow(isLoggedIn = loggedIn)
                Platform.Desktop -> handleDesktopFlow(isLoggedIn = loggedIn)
            }
        }
        //Could provide a splash screen here or loading page, but need to work out the details with
        //Jeff if he wants it.
        return stack {}
    }
}
