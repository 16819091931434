package com.picme.views.share

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.session
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionUserData
import kotlinx.coroutines.launch

fun String.anonymousIfEmpty(): String = this.ifEmpty { "Anonymous" }

fun String.userIconColor(): Color {
    val notEmpty = this.anonymousIfEmpty()
    val hash = notEmpty.hashCode()
    val r = (hash shr 16) and 0xFF
    val g = (hash shr 8) and 0xFF
    val b = hash and 0xFF
    return Color.fromHexString("#${r.toString(16)}${g.toString(16)}${b.toString(16)}")
}

class Guests(
    val collectionId: CollectionId,
    val navToGuest: suspend (guest: CollectionUserData) -> Unit
) : Screen {

    override fun ViewWriter.render() {
        expanding - unpadded - stack {
            val sharees = Property<List<CollectionUserData>>(emptyList())
            val loading = Property(false)

            launch {
                loading set true
                val coll = session.awaitNotNull().collection2.getCollectionLive(collectionId)

                session.awaitNotNull().collection2.listUsers(collectionId).let {
                    sharees set it.users.filterNot { it.userId == coll().collection.creatorUserId }
                        .sortedByDescending { it.participationRights.value }
                }
                loading set false
            }

            padded - col {
                ::exists { loading() }
                space(); space();space()
                centered - activityIndicator()
            }
            centered - padded - text {
                content = "There are no guests."
                ::exists { !loading() && sharees().isEmpty() }
            }


            expanding - recyclerView {
                ::exists { !loading() && sharees().isNotEmpty() }
                children(sharees) { guest ->
                    col {
                        button {
                            row {
                                atStart - guestIconAndName(guest)
                                expanding - space {}
                                centered - icon { source = PIcon.chevronright }
                            }
                            onClick {
                                navToGuest(guest())
                            }
                        }
                    }
                }
            }
        }
    }
}

fun ViewWriter.guestIconAndName(guest: Readable<CollectionUserData>) {
    row {
        val isSelf = shared {
            guest().userId == session.value!!.authenticatedUser.state.raw.userId
        }
        val profileImg = shared {
            if (isSelf()) session.value!!.authentication.profileImage() else null
        }
        userIcon(
            profileImg,
            guest.lens { it.name.anonymousIfEmpty() },
            bgColor = {
                if (guest().userId == session.value!!.authenticatedUser.state.raw.userId) {
                    Color.bluePurpleGradient
                } else guest().name.userIconColor()
            }
        )
        centered - col {
            spacing = 0.rem
            h4 { ::content { guest().name.anonymousIfEmpty() } }
            FadedForeground.onNext - text {
                ::content { guest().participationRights.rightsOnCollection() }
            }
        }
    }
}

fun ViewWriter.userIcon(
    image: Readable<ImageSource?>,
    name: Readable<String>,
    bgColor: (ReactiveContext.() -> Paint) = { Color.white }
) {
    centered -  sizeConstraints(
        width = 3.rem,
        height = 3.rem
    ) -  unpadded - stack {
        spacing = 0.dp
        dynamicTheme {
            val c = bgColor()
            if (image() == null) {
                ThemeDerivation {
                    it.copy(
                        id = "without-image",
                        background = c,
                        foreground = Color.white,
                        cornerRadii = CornerRadii.ForceConstant(100.rem)
                    ).withBack
                }
            } else {
                ThemeDerivation {
                    it.copy(id = "with-image", cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack
                }
            }
        }
        centered - h6 {
            ::exists { image() == null }
            ::content { name().take(1) }
        }
        image {
            ::exists { image() != null }
            scaleType = ImageScaleType.Crop
            refreshOnParamChange = true
            ::source { image() }
        }
    }
}
