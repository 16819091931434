package com.picme.sso

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.UnauthScreen
import com.picme.components.authScreen
import com.picme.components.importantButton


@Routable("/sso-google/error/{code}/{message}")
class ErrorScreenGoogle(val code: String, val message: String) : Page, UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {
        return expanding - unpadded - stack {
            authScreen("An error has occurred", Constant(false)) {
                warning - col {
                    text(message)
                }
            }
        }
    }
}
@Routable("/sso-apple/error/{code}/{message}")
class ErrorScreenApple(val code: String, val message: String) : Page, UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {
        return expanding - unpadded - stack {
            authScreen("An error has occurred", Constant(false)) {
                warning - col {
                    text(message)
                }
            }
        }
    }
}