package com.picme.sdk2.generated.poll

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object PollHandlerEndpointsSerializer : KSerializer<PollHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("PollHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: PollHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): PollHandlerEndpoints {
        val string = decoder.decodeString()
        return PollHandlerEndpoints(string)
    }
}


@Serializable(with = PollHandlerEndpointsSerializer::class)
data class PollHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')

    suspend fun patchPoll(
        scope: DataScope?,
        pollId: PollId,
        body: PatchPollBody,
        accessToken: suspend () -> String? = { null }
    ): PatchPollResponse = fetch(
        url = "$url/poll/${pollId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
            "scope" to scope.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )

    suspend fun deletePoll(
        scope: DataScope?,
        pollId: PollId,
        accessToken: suspend () -> String? = { null }
    ): DeletePollResponse = fetch(
        url = "$url/poll/${pollId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
            "scope" to scope.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun createPoll(
        scope: DataScope?,
        body: CreatePollBody,
        accessToken: suspend () -> String? = { null }
    ): CreatePollResponse = fetch(
        url = "$url/poll",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "scope" to scope.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )

    suspend fun listPolls(
        filterQuery: PollQuery?,
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
        accessToken: suspend () -> String? = { null }
    ): ListPollsResponse = fetch(
        url = "$url/poll",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "filterQuery" to filterQuery.toParamString(),
            "itemsPerPage" to itemsPerPage.toParamString(),
            "continuation" to continuation.toParamString(),
            "forUserId" to forUserId.toParamString(),
        ).filter { it.value != "null" },
    )
}

