package com.picme

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.ImageRemote
import com.lightningkite.kiteui.reactive.awaitNotNull
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.picme.components.*
import com.picme.sdk2.Session
import com.picme.sdk2.caching.emptyFilter
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.sdk2.json
import kotlinx.coroutines.delay
import kotlinx.serialization.Serializable
import kotlin.math.min

data class Zip(
    val collectionId: CollectionId,
    val uploadIds: List<UploadId>?,
    var full: String? = null,
    var partial: String? = null
)

@Serializable
data class ZipStatus(
    val count: Int,
    val bytes: Int,
    val utcRequestTime: String,// "2024-05-16T17:14:38.1690096Z"
    val utcEstimatedCompletionTime: String, //  "2024-05-16T17:14:44.8514116Z"
    val utcStartTime: String? = null,
    val zipLink: String? = null, // When finished, this will be a link to the zip file
    val error: String? = null
)

suspend fun Zip.start(session: Session): ZipStatus {
    if (uploadIds == null) {
        full = session.collection2.getOrStartFullZipOfOriginals(
            collectionId
        ).getZipStatusUri
    } else {
        partial = session.collection2.getOrStartPartialZipOfOriginals(
            collectionId, uploadIds
        ).getZipStatusUri
    }
    return checkStatus()
}

suspend fun Zip.checkStatus(): ZipStatus {
    return fetch((full ?: partial)!!).let {
        json.decodeFromString<ZipStatus>(it.text())
    }
}

actual suspend fun ViewWriter.collectionDownload(collectionId: CollectionId, uploadIds: List<UploadId>?) {

    val zip = Zip(collectionId, uploadIds)
    val start = zip.start(session.awaitNotNull())

    openProgressModal("Preparing ${start.count} files", execute = {
        image = ImageRemote(
            session.awaitNotNull().collection2.listUploadsLive(collectionId, emptyFilter).all().first().thumbnailUrl
        )
        while (true) {
            val status = zip.checkStatus()
            if (status.zipLink != null) {
                successZipLink set status.zipLink
                break
            }
            if (status.error != null) {
                launchGlobal {
                    showConfirmDialog(
                        title = "Error",
                        content = "An error has occurred while creating the zip file. Please try again later.",
                        onConfirm = {}
                    )
                }
                break
            }
            individualItemProgress += (1 - individualItemProgress) * 0.2f
            delay(1500)
        }

    },
        onComplete = {
            showConfirmDialog(
                title = "${start.count} ${"file".pluralize(start.count)} ready",
                content = "",
                confirmLabel = "Download",
                onConfirm = {
                    successZipLink()?.let {
                        ExternalServices.download(
                            "picme-collection-${collectionId.raw}.zip".validDownloadableName(),
                            it
                        )
                    }
                    showToast("File successfully downloaded")
                }
            )
        }
    )
}

actual suspend fun ViewWriter.chooseImagesFromLibrary(onResult: suspend (List<FileReference>) -> Unit) {}