package com.picme.sso

import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.h4
import com.lightningkite.kiteui.views.direct.stack
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.warning
import com.picme.*
import com.picme.components.dialogGeneric
import com.picme.components.importantButton
import com.picme.sdk2.Session
import com.picme.sdk2.generated.UserAuthenticated
import com.picme.sdk2.unauthApi
import com.picme.views.LoginOrSignUp

@Routable("/sso-apple/{access}/{refresh}")
class AppleSSOLanding(val access: String, val refresh: String) : Page, UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {

        suspend fun signIn() {
            val userAuth = unauthApi().authenticationHandler.refreshUserAuthentication(
                refresh
            ) { access }.successfulAuthentication?.authenticatedUser

            if (userAuth == null) {
                mainScreenNavigator.reset(LoginOrSignUp(false))
            } else {
                val newSession = Session(
                    unauthApi(), UserAuthenticated(
                        accessToken = access,
                        tokenType = "access_token",
                        refreshToken = refresh,
                        expiresInSeconds = 100
                    ), userAuth
                )
                println(newSession.collection2.listCollectionsLive().all())
                session set newSession
                navigateToCollOrLanding()

            }
        }

        return stack {
            launchGlobal { signIn() }
            fullScreenLoading()
        }
    }

}