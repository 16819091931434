package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.blueLink
import com.picme.components.picmeIconDisplay
import com.picme.components.smallIcon
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.InviteCode
import com.picme.sdk2.generated.InviteCodeId
import com.picme.sdk2.generated.collection2.CollectionSharingAuthorizationCode
import com.picme.sdk2.generated.collection2.GetCollectionResponse2
import com.picme.sdk2.toSafeDecoded
import com.picme.sdk2.toSafeEncoded
import com.picme.sdk2.unauthApi
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable

@Routable("in/{codeId}")
class QrAccept(val codeId: String) : Page, NoTopBar, UnauthScreen {
    override val title get() = Constant("")
    override fun ViewWriter.render2(): ViewModifiable {
        launch {
            lastScannedQr.value = QRScanEvent(codeId, Clock.System.now())
        }
        return stack {
            reactiveSuspending {
                suspend fun getScreen(): Page {
                    try {
                        val qr =
                            unauthApi().collectionHandler2.getInviteCode(InviteCodeId(codeId.toSafeDecoded())).inviteCode

                        if (qr.temporarilyDisabled) {
                            return PagePaused
                        }

                        val collectionId = qr.clientInfo()?.collectionId?.let(::CollectionId)

                        val collection = collectionId?.let { unauthApi().collectionHandler2.getCollection(it) }
                        collection?.getCoverPhotoUri?.imageIfExists()

                        if (collection == null && qr.clientInfo()?.type != InviteType.Referral) {
                            return PagePaused
                        }

                        acceptingInvite set AcceptInvite(qrCode = qr, collection = collectionId)

                        return when (qr.clientInfo()?.type) {
                            InviteType.RequestUploads -> RequestUploadsAccept(collection!!, "in/${codeId}")
                            InviteType.ShareColl -> ShareAccept(collection!!, "in/${codeId}")
                            InviteType.Referral -> ReferralAccept
                            null -> PageNotFound
                        }
                    } catch (e: Exception) {
                        return PageNotFound
                    }
                }

                screenNavigator.replace(getScreen())
                fullScreenLoading()
            }
        }
    }
}

object ReferralAccept : Page, NoTopBar, UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {
        return qrAcceptScreen {
            col {
                centered - bold - h2("PicMe Invitation")
                centered - text {
                    content = "You've been invited to sign up\n for a PicMe account"; align = Align.Center
                }
                space()
                centered - captureExperiencesIcon()
                space()
                space()

                buttonTheme - important - button {
                    centered - bold - h4 {
                        content = "Get Started"
                        align = Align.Center
                    }
                    onClick {
                        if (session()?.isVerifiedAccount() == true) { // Don't accept invite if already a user
                            acceptingInvite set null
                        }
                        screenNavigator.navigate(LoginOrSignUp())
                    }
                }

                skipForNow()
            }
        }
    }
}

private fun ViewWriter.inviteLandingTopBar() = stack {
    atStart - button {
        ::exists { session()?.authenticatedUser() != null }
        smallIcon(PIcon.chevronleft)
        onClick {
            if (!screenNavigator.goBack()) screenNavigator.navigate(LoginOrSignUp(true))
        }
    }
    centered - sizeConstraints(
        width = 2.5.rem,
        height = 2.5.rem
    ) - padded - ThemeDerivation { it.withoutBack }.onNext - button {
        spacing = 0.rem
        centered - picmeIconDisplay()
        onClick {
            if (!screenNavigator.goBack()) screenNavigator.navigate(LoginOrSignUp(true))
        }
    }
}

class ShareAccept(val collection: GetCollectionResponse2, val redirectTo: String) : Page, NoTopBar, UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {
        return unpadded - themedBg - scrolls - qrAcceptScreen(bottom = { getTheAppCard(redirectTo) }) {
            col {
                inviteLandingTopBar()
                space {}
                centered - bold - h2("Guest Invitation")
                centered - text {
                    content = "You've been invited to a PicMe Collection."; align = Align.Center
                }

                collPlaceholder(sharedSuspending { collection.getCoverPhotoUri.imageIfExists() })

                centered - h4 {
                    ::content { collection.collection.name }
                    align = Align.Center
                }

                space()
                buttonTheme - important - button {
                    centered - bold - h4 {
                        content = "Accept Invite"
                        align = Align.Center
                    }
                    onClick {
                        if (session() == null) {
                            screenNavigator.navigate(LoginOrSignUp(true))
                        } else {
                            acceptQrCode()
                        }
                    }
                }
                skipForNow()

            }
        }
    }
}

class RequestUploadsAccept(val collection: GetCollectionResponse2, val redirectTo: String) : Page, NoTopBar,
    UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {
        return unpadded - themedBg - scrolls - qrAcceptScreen(bottom = { getTheAppCard(redirectTo) }) {
            col {
                inviteLandingTopBar()
//                space {}
                collPlaceholder(sharedSuspending { collection.getCoverPhotoUri.imageIfExists() })
                centered - h4 {
                    ::content { collection.collection.name }
                    align = Align.Center
                }
                FadedForeground.onNext - centered - text {
                    ::content { collection.collection.message }
                    align = Align.Center
                }

                space()
                space()
                uploadPhotosButton {
                    val encoded = collection.collection.collectionId.raw.toSafeEncoded()
                    val isAuthenticated = session.value?.isVerifiedAccount() == true
                    val screen =
                        if (isAuthenticated) CollectionDetail(encoded)
                        else PartialAuthUploadView(encoded)

                    uploadExternalFilesFirstTime(
                        collId = collection.collection.collectionId,
                        onFinish = {
                            screenNavigator.navigate(screen)
                        }
                    )
                }
            }
        }
    }
}


fun ViewWriter.qrAcceptScreen(
    bottom: ContainingView.() -> Unit = {},
    setup: ContainingView.() -> Unit
): ViewModifiable {
    return stack {
        gravity(Align.Center, Align.Start) - sizeConstraints(width = 24.rem) - padded - col {
            space {}
            space { ::exists { WindowInfo().height > 800.dp } }
            col { centered - sizeConstraints(width = 22.rem) - setup() }
            space()
            bottom()
        }
    }
}

fun ViewWriter.collPlaceholder(cover: Readable<ImageRemote?>) {
    centered - sizeConstraints(width = 12.rem, height = 12.rem) - image {
        exists = false
        ::exists { cover() == null }
        source = Resources.picmePaige
    }
    centered - ThemeDerivation {
        it.copy(cornerRadii = CornerRadii.ForceConstant(2.5.rem)).withoutBack
    }.onNext - sizeConstraints(width = 12.rem, height = 12.rem) - image {
        exists = true
        ::exists { cover() != null }
        scaleType = ImageScaleType.Crop
        ::source { cover() }
    }
}

fun ViewWriter.skipForNow() = centered - blueLink - button {
    text("Skip for Now")
    onClick {
        if (session() == null) {
            ExternalServices.openTab("https://picme.com")
        } else {
            acceptingInvite set null
            navigateToCollOrLanding()
        }
    }
}


fun ViewWriter.uploadPhotosButton(onClick: suspend () -> Unit) = buttonTheme - important - button {
    centered - row {
        centered - ThemeDerivation { it.copy(iconOverride = Color.white).withoutBack }.onNext - icon {
            source = PIcon.upload
        }
        centered - bold - h4 {
            content = "Upload Photo"
            align = Align.Center
        }
    }
    onClick {
        onClick()
    }
}


fun ViewWriter.captureExperiencesIcon() {
    centered - picmeIconDisplay(64.dp)
    ThemeDerivation { it.copy(foreground = Color.picmePurple).withoutBack }.onNext - HeaderSemantic.onNext - text {
        align = Align.Center
        content = "capture experiences"
    }
}

@Serializable
data class LegacyAccept(
    val sharingAuthCode: CollectionSharingAuthorizationCode? = null
)

@Serializable
data class AcceptInvite(
    val qrCode: InviteCode? = null,
    val legacy: LegacyAccept? = null,
    val collection: CollectionId?,
    val alreadyAccepted: Boolean = false
)

val acceptingInvite = Property<AcceptInvite?>(null)


// collection
// login-or-signup
// in/:codeId
fun ViewWriter.getTheAppCard(redirectTo: String, textContent: String = "Get our free app. Then re-scan QR code.") {
    card - ThemeDerivation {
        it.copy(background = Color.white.applyAlpha(0.0f), outline = it.outline.darken(0.1f)).withBack
    }.onNext - col {
        ::exists { Platform.current == Platform.Web && Platform.usesTouchscreen }
        ThemeDerivation {
            theme.copy(font = theme.font.copy(weight = 700, size = 0.9.rem)).withoutBack
        }.onNext - text {
            content = textContent
            align = Align.Center
        }
        row {
            spacing = 16.dp
            expanding - sizeConstraints(minHeight = 5.rem) - button {
                spacing = 0.dp
                image {
                    source = Resources.googleBadge
                }
                onClick { ExternalServices.openTab("https://play.google.com/store/apps/details?id=com.picme.neo&referrer=utm_source=https://app.picme.com/${redirectTo}") }
            }
            expanding - sizeConstraints(minHeight = 5.rem) - button {
                spacing = 0.dp
                image {
                    source = Resources.appleBadge
                }
                onClick { ExternalServices.openTab("https://apps.apple.com/app/picme-capture-experiences/id6499455857") }

            }
        }
    }
}


object PagePaused : Page, NoTopBar, UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {
        return qrAcceptScreen {
            col {
                centered - bold - h2("Page Paused")
                centered - text {
                    content = "The page you are trying to\n access has been paused."; align = Align.Center
                }
                space()
                centered - captureExperiencesIcon()
                space()
                space()
            }
        }
    }
}


object PageNotFound : Page, NoTopBar, UnauthScreen {
    override fun ViewWriter.render2(): ViewModifiable {
        return qrAcceptScreen {
            col {
                centered - bold - h2("Page Paused")
                centered - text {
                    content = "The page you are trying to\n access does not exist."; align = Align.Center
                }
                space()
                centered - captureExperiencesIcon()
                space()
                space()
            }
        }
    }
}
