package com.picme

import com.lightningkite.serialization.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsApps() { AppsModelsObject }
object AppsModelsObject {
    init {
        SerializationRegistry.master.registerApps()
        com.picme.sdk2.prepareUploadConditionTypeFields()
    }
}

@OptIn(ExperimentalSerializationApi::class)
fun SerializationRegistry.registerApps() {
    register(com.picme.InviteType.serializer())
    register(com.picme.ClientInfo.serializer())
    register(com.picme.GridSize.serializer())
    register(com.picme.QRScanEvent.serializer())
    register(com.picme.sdk2.UploadConditionType.serializer())
    register(com.picme.sdk2.Retainable.serializer(NothingSerializer()).descriptor.serialName) { com.picme.sdk2.Retainable.serializer(it[0]) }
    register(com.picme.sdk2.generated.ApiEndpoints.serializer())
    register(com.picme.sdk2.generated.ListAdsResponse.serializer())
    register(com.picme.sdk2.generated.ListedAd.serializer())
    register(com.picme.sdk2.generated.AdSize.serializer())
    register(com.picme.sdk2.generated.HttpRedirect.serializer())
    register(com.picme.sdk2.generated.UserId.serializer())
    register(com.picme.sdk2.generated.MoreAuthenticationRequired.serializer())
    register(com.picme.sdk2.generated.CollectNewPassword.serializer())
    register(com.picme.sdk2.generated.NewPasswordChallengeToken.serializer())
    register(com.picme.sdk2.generated.AuthenticationComplete.serializer())
    register(com.picme.sdk2.generated.UserData.serializer())
    register(com.picme.sdk2.generated.UserAuthenticated.serializer())
    register(com.picme.sdk2.generated.CollectionId.serializer())
    register(com.picme.sdk2.generated.LinkRelationshipType.serializer())
    register(com.picme.sdk2.generated.CreateInviteCodeResponse.serializer())
    register(com.picme.sdk2.generated.InviteCode.serializer())
    register(com.picme.sdk2.generated.LinkActivator.serializer())
    register(com.picme.sdk2.generated.LinkTemplate.serializer())
    register(com.picme.sdk2.generated.LinkConflictResolutionEnum.serializer())
    register(com.picme.sdk2.generated.LinkConflictResolution.serializer())
    register(com.picme.sdk2.generated.LinkRelationship.serializer())
    register(com.picme.sdk2.generated.InviteCodeId.serializer())
    register(com.picme.sdk2.generated.RecordGlobalId.serializer())
    register(com.picme.sdk2.generated.Text.serializer())
    register(com.picme.sdk2.generated.MimeType.serializer())
    register(com.picme.sdk2.generated.DateTimeRange.serializer())
    register(com.picme.sdk2.generated.RightsEnum.serializer())
    register(com.picme.sdk2.generated.Rights.serializer())
    register(com.picme.sdk2.generated.ActivateInviteCodeResponse.serializer())
    register(com.picme.sdk2.generated.Tumbler.serializer())
    register(com.picme.sdk2.generated.CreateQrCodeImageResponse.serializer())
    register(com.picme.sdk2.generated.ListActivatedUsersResponse.serializer())
    register(com.picme.sdk2.generated.ActivatedUserData.serializer())
    register(com.picme.sdk2.generated.GetInviteCodeResponse.serializer())
    register(com.picme.sdk2.generated.PatchInviteCodeBody.serializer())
    register(com.picme.sdk2.generated.CreateInviteCodeBody.serializer())
    register(com.picme.sdk2.generated.ListInviteCodesResponse.serializer())
    register(com.picme.sdk2.generated.DataScope.serializer())
    register(com.picme.sdk2.generated.ad.AdHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.authentication.AuthenticationHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.authentication.SignUpUserResponse.serializer())
    register(com.picme.sdk2.generated.authentication.StartAuthenticationResponse.serializer())
    register(com.picme.sdk2.generated.authentication.CheckUsernameResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetUserProfileUploadUrlResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetUserProfileInfoResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetUserStateDataUrlsResponse.serializer())
    register(com.picme.sdk2.generated.authentication.SetUserAttributesResponse.serializer())
    register(com.picme.sdk2.generated.authentication.ChangeUserPasswordResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetMyUserInfoResponse.serializer())
    register(com.picme.sdk2.generated.authentication.RefreshUserAuthenticationResponse.serializer())
    register(com.picme.sdk2.generated.authentication.AuthLinkRelationshipTypes.serializer())
    register(com.picme.sdk2.generated.authentication.ReferralType.serializer())
    register(com.picme.sdk2.generated.authentication.DeleteUserResponse.serializer())
    register(com.picme.sdk2.generated.authentication.MergeIntoAccountResponse.serializer())
    register(com.picme.sdk2.generated.authentication.ListReferralsResponse.serializer())
    register(com.picme.sdk2.generated.authentication.Referral.serializer())
    register(com.picme.sdk2.generated.authentication.CheckUserAuthenticationResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetRightsOnResponse.serializer())
    register(com.picme.sdk2.generated.authentication.AuthenticateAsGuestResponse.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionHandler2Endpoints.serializer())
    register(com.picme.sdk2.generated.collection2.UploadId.serializer())
    register(com.picme.sdk2.generated.collection2.GetDeletedUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.Upload.serializer())
    register(com.picme.sdk2.generated.collection2.UploadState.serializer())
    register(com.picme.sdk2.generated.collection2.ListLinksToResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.Link.serializer())
    register(com.picme.sdk2.generated.collection2.FileSystemRelativeFilePath.serializer())
    register(com.picme.sdk2.generated.collection2.GetUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.UploaderInfo.serializer())
    register(com.picme.sdk2.generated.collection2.PatchUploadBody.serializer())
    register(com.picme.sdk2.generated.collection2.PatchUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.DeleteUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.CopyUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.GetCollectionModificationStampResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.PutCollectionCoverPhotoResponse.serializer())
    register(com.picme.sdk2.generated.collection2.ListDeletedUploadsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ListedUpload.serializer())
    register(com.picme.sdk2.generated.collection2.RestoreDeletedUploadsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ListUploadKeywordsResponse.serializer())
    register(com.picme.sdk2.generated.collection2.GetOrStartPartialZipOfOriginalsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.GetOrStartFullZipOfOriginalsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionSharingAuthorizationCode.serializer())
    register(com.picme.sdk2.generated.collection2.CreateUploadBody.serializer())
    register(com.picme.sdk2.generated.collection2.CreateUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ConditionType.serializer())
    register(com.picme.sdk2.generated.collection2.UploadQuery.serializer())
    register(com.picme.sdk2.generated.collection2.Int64Range.serializer())
    register(com.picme.sdk2.generated.collection2.ListUploadsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.DeleteAllUploadsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.RevokeRightsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ModifyRightsResponse.serializer())
    register(com.picme.sdk2.generated.collection2.GetCollectionRightsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.GetCollectionResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.PCollection.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionPlan.serializer())
    register(com.picme.sdk2.generated.collection2.GenericIdentifier.serializer())
    register(com.picme.sdk2.generated.collection2.PatchCollectionBody.serializer())
    register(com.picme.sdk2.generated.collection2.PatchCollectionResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.DeleteCollectionResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ListUsersResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionUserData.serializer())
    register(com.picme.sdk2.generated.collection2.CreateCollectionBody.serializer())
    register(com.picme.sdk2.generated.collection2.CreateCollectionResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionQuery.serializer())
    register(com.picme.sdk2.generated.collection2.ListCollectionsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ListedCollection.serializer())
    register(com.picme.sdk2.generated.collection2.UploadOriginalAvailability.serializer())
    register(com.picme.sdk2.generated.discussion.DiscussionHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.discussion.PatchDiscussionEntryBody.serializer())
    register(com.picme.sdk2.generated.discussion.PatchDiscussionEntryResponse.serializer())
    register(com.picme.sdk2.generated.discussion.DiscussionEntry.serializer())
    register(com.picme.sdk2.generated.discussion.DeleteDiscussionEntryResponse.serializer())
    register(com.picme.sdk2.generated.discussion.CreateDiscussionEntryBody.serializer())
    register(com.picme.sdk2.generated.discussion.CreateDiscussionEntryResponse.serializer())
    register(com.picme.sdk2.generated.discussion.ListDiscussionEntriesResponse.serializer())
    register(com.picme.sdk2.generated.discussion.ListedDiscussionEntry.serializer())
    register(com.picme.sdk2.generated.discussion.DiscussionId.serializer())
    register(com.picme.sdk2.generated.discussion.PatchDiscussionBody.serializer())
    register(com.picme.sdk2.generated.discussion.PatchDiscussionResponse.serializer())
    register(com.picme.sdk2.generated.discussion.Discussion.serializer())
    register(com.picme.sdk2.generated.discussion.GetDiscussionResponse.serializer())
    register(com.picme.sdk2.generated.discussion.DeleteDiscussionResponse.serializer())
    register(com.picme.sdk2.generated.discussion.CreateDiscussionBody.serializer())
    register(com.picme.sdk2.generated.discussion.CreateDiscussionResponse.serializer())
    register(com.picme.sdk2.generated.discussion.DiscussionQuery.serializer())
    register(com.picme.sdk2.generated.discussion.ListDiscussionsResponse.serializer())
    register(com.picme.sdk2.generated.discussion.ListedDiscussion.serializer())
    register(com.picme.sdk2.generated.invitation.InvitationHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.notification.NotificationHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.notification.FirebaseCloudMessagingProjectConfig.serializer())
    register(com.picme.sdk2.generated.notification.GetFirebaseCloudMessagingProjectConfigResponse.serializer())
    register(com.picme.sdk2.generated.notification.FirebaseCloudMessagingAppConfig.serializer())
    register(com.picme.sdk2.generated.notification.GetFirebaseCloudMessagingAppConfigResponse.serializer())
    register(com.picme.sdk2.generated.notification.NotificationSubscription.serializer())
    register(com.picme.sdk2.generated.notification.NotificationFrequency.serializer())
    register(com.picme.sdk2.generated.notification.NotificationChannel.serializer())
    register(com.picme.sdk2.generated.notification.NotificationType.serializer())
    register(com.picme.sdk2.generated.notification.ListNotificationSubscriptionConfigurationsResponse.serializer())
    register(com.picme.sdk2.generated.poll.PollHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.poll.PollId.serializer())
    register(com.picme.sdk2.generated.poll.PatchPollBody.serializer())
    register(com.picme.sdk2.generated.poll.PatchPollResponse.serializer())
    register(com.picme.sdk2.generated.poll.Poll.serializer())
    register(com.picme.sdk2.generated.poll.DeletePollResponse.serializer())
    register(com.picme.sdk2.generated.poll.CreatePollBody.serializer())
    register(com.picme.sdk2.generated.poll.CreatePollResponse.serializer())
    register(com.picme.sdk2.generated.poll.PollQuery.serializer())
    register(com.picme.sdk2.generated.poll.ListPollsResponse.serializer())
    register(com.picme.sdk2.generated.poll.ListedPoll.serializer())
    register(com.picme.sdk2.generated.qrgeneration.QrGenerationHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.sso.SsoHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.sso.ContinueGoogleAuthenticationResponse.serializer())
    register(com.picme.sdk2.generated.sso.GoogleAuthConfig.serializer())
    register(com.picme.sdk2.generated.sso.SsoAuthCallbackEndpoint.serializer())
    register(com.picme.sdk2.generated.sso.GetGoogleAuthenticationConfigResponse.serializer())
    register(com.picme.sdk2.generated.sso.ContinueAppleAuthenticationResponse.serializer())
    register(com.picme.sdk2.generated.sso.AppleAuthConfig.serializer())
    register(com.picme.sdk2.generated.sso.GetAppleAuthenticationConfigResponse.serializer())
    register(com.picme.sdk2.BackendUrls.serializer())
    register(com.picme.sdk2.UploadImageInfo.serializer())
    register(com.picme.views.Environment.serializer())
    register(com.picme.views.LegacyAccept.serializer())
    register(com.picme.views.AcceptInvite.serializer())
}
