package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactiveSuspending
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.stack
import com.lightningkite.kiteui.views.launch
import com.lightningkite.kiteui.views.reactiveScope
import com.picme.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionSharingAuthorizationCode
import com.picme.sdk2.toSafeDecoded
import com.picme.sdk2.unauthApi
import kotlinx.coroutines.launch


@Routable("up/{urlSafeCollId}")
class LegacyUpload(val urlSafeCollId: String) : Screen, UnauthScreen, NoTopBar {
    override fun ViewWriter.render() {
        stack {
            launch {
                acceptingInvite set AcceptInvite(
                    legacy = LegacyAccept(),
                    collection = CollectionId(urlSafeCollId.toSafeDecoded())
                )
                mainScreenNavigator.reset(LegacyInvite)
            }
        }
    }
}


@Routable("up/{urlSafeCollId}/{sharingAuthCode}")
class LegacyShare(val urlSafeCollId: String, val sharingAuthCode: String) : Screen, UnauthScreen, NoTopBar {
    override fun ViewWriter.render() {
        acceptingInvite.value = AcceptInvite(
            legacy = LegacyAccept(CollectionSharingAuthorizationCode(sharingAuthCode)),
            collection = CollectionId(urlSafeCollId.toSafeDecoded())
        )
        mainScreenNavigator.reset(LegacyInvite)
    }
}

@Routable("qr/{qrCodeId}")
class QrCodeOld(val qrCodeId: String) : Screen, UnauthScreen, NoTopBar {
    override fun ViewWriter.render() {
        mainScreenNavigator.reset(QrAccept(qrCodeId))
    }
}


object LegacyInvite : Screen, UnauthScreen, NoTopBar {
    override fun ViewWriter.render() {
        stack {
            reactiveSuspending {
                createAccountIfNewUser()
                val collId = acceptingInvite()?.collection ?: return@reactiveSuspending
                val collection = unauthApi().collectionHandler2.getCollection(collId)

                if (acceptingInvite()?.legacy?.sharingAuthCode != null) {
                    screenNavigator.reset(ShareAccept(collection, ""))
                }
                if (acceptingInvite()?.legacy?.sharingAuthCode == null) {
                    screenNavigator.reset(RequestUploadsAccept(collection, ""))
                }
                session()
            }

            fullScreenLoading()
        }
    }
}