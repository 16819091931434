package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.createCollectionModal

@Routable("collection")
object CollectionLanding : Screen, UnauthScreen {
    override val title = Constant("")
    override fun ViewWriter.render() {
        themedBg - stack {
            col {
                centered - sizeConstraints(width = 20.rem, height = 20.rem) - image {
                    source = Resources.picmePaige
                }
                space()

                centered - FadedForeground.onNext - text("To get started, create a collection")
                centered - sizeConstraints(width = 28.rem, height = 42.dp) - buttonTheme - important - button {
                    centered - row {
                        icon { source = PIcon.add }
                        centered - h6("Create Collection")
                        onClick {
                            //only prompt for a name if there isn't an active session
                            if (session.value == null) {
                                val usersName = askForName().await()
                                if(usersName.isNotEmpty()) {
                                    createCollectionModal()
                                }
                            } else {
                                createCollectionModal()
                            }
                        }
                    }
                }
                centered - button {
                    ::exists { sharedSuspending { session()?.isVerifiedAccount() != true }() }
                    themeChoice += ThemeDerivation { it.copy(foreground = Color.darkPurple).withoutBack }
                    row {
                        bold - text { content = "Log in / Sign up" }
                    }
                    onClick {
                        emailOrPhone set ""
                        dialogScreenNavigator.clear()
                        mainScreenNavigator.navigate(LoginOrSignUp(true))
                    }
                }
                space(); space()
                getTheAppCard("collection", "Get our free app.")
            }
        }
    }
}