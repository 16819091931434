package com.picme.sdk2

import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.HttpMethod
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.PersistentProperty
import com.picme.sdk2.generated.ApiEndpoints
import com.picme.sdk2.generated.ad.AdHandlerEndpoints
import com.picme.sdk2.generated.authentication.AuthenticationHandlerEndpoints
import com.picme.sdk2.generated.collection2.CollectionHandler2Endpoints
import com.picme.sdk2.generated.discussion.DiscussionHandlerEndpoints
import com.picme.sdk2.generated.invitation.InvitationHandlerEndpoints
import com.picme.sdk2.generated.notification.NotificationHandlerEndpoints
import com.picme.sdk2.generated.poll.PollHandlerEndpoints
import com.picme.sdk2.generated.qrgeneration.QrGenerationHandlerEndpoints
import com.picme.sdk2.generated.sso.SsoHandlerEndpoints
import com.picme.views.selectedBackend
import kotlinx.serialization.Serializable


val api = PersistentProperty<ApiEndpoints?>("picme-server-endpoints", null)

@Serializable
data class BackendUrls(
    val adHandler: String,
    val authenticationHandler: String,
    val collectionHandler2: String,
    val notificationHandler: String,
    val discussionHandler: String,
    val ssoHandler: String,
    val pollHandler: String,
    val qrGenerationHandler: String,
    val invitationHandler: String
)

suspend fun fetchAndSetBackendUrls(baseUrl: String): ApiEndpoints {
    val urls = com.lightningkite.kiteui.fetch("${baseUrl}/endpoints.json", HttpMethod.GET).let {
        val decoded = json.decodeFromString<BackendUrls>(it.text())
        BackendUrls(
            adHandler = "${baseUrl}/${decoded.adHandler}",
            authenticationHandler = "${baseUrl}/${decoded.authenticationHandler}",
            collectionHandler2 = "${baseUrl}/${decoded.collectionHandler2}",
            notificationHandler = "${baseUrl}/${decoded.notificationHandler}",
            discussionHandler = "${baseUrl}/${decoded.discussionHandler}",
            ssoHandler = "${baseUrl}/${decoded.ssoHandler}",
            pollHandler = "${baseUrl}/${decoded.pollHandler}",
            qrGenerationHandler = "${baseUrl}/${decoded.qrGenerationHandler}",
            invitationHandler = "${baseUrl}/${decoded.invitationHandler}",
        )
    }
    return ApiEndpoints(
        adHandler = AdHandlerEndpoints(urls.adHandler),
        authenticationHandler = AuthenticationHandlerEndpoints(urls.authenticationHandler),
        collectionHandler2 = CollectionHandler2Endpoints(urls.collectionHandler2),
        notificationHandler = NotificationHandlerEndpoints(urls.notificationHandler),
        discussionHandler = DiscussionHandlerEndpoints(urls.discussionHandler),
        ssoHandler = SsoHandlerEndpoints(urls.ssoHandler),
        pollHandler = PollHandlerEndpoints(urls.pollHandler),
        qrGenerationHandler = QrGenerationHandlerEndpoints(urls.qrGenerationHandler),
        invitationHandler = InvitationHandlerEndpoints(urls.invitationHandler)
    ).also {
        api set it
    }
}

val unauthApi: Readable<ApiEndpoints> = sharedSuspending {
    api.await() ?: fetchAndSetBackendUrls(selectedBackend())
}

@Serializable
data class UploadImageInfo(
    val uploadId: String,
    val uploadUrl: String,
    val metadataUploadUrl: String,
    val anonymousOwnershipToken: String
)

expect class BackgroundUploadManager() {
    fun startBackgroundUploadRequest(uploadInfo: UploadImageInfo, file: FileReference)
}

val uploadManager = BackgroundUploadManager()
val backgroundUploads = Property(listOf<BackgroundUploadStatus>())

data class BackgroundUploadStatus(
    val uploadInfo: UploadImageInfo? = null,
    val progress: Float = 0f,
    val state: State = State.InProgress
) {
    enum class State { InProgress, Complete, Failed }
}