package com.picme.sdk2.generated.poll

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*



@Serializable
@JvmInline
value class PollId(val raw: String = "")

/** A record containing the structure of the body for the <see cref="M:PicMeApi.PollApis.PatchPoll(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,System.Nullable{SimpleDatabase.DataScope},SimpleDatabase.PollId,System.Text.Json.JsonDocument)" /> API.
            Any parts of the structure may be left unspecified, which will cause the existing value to be retained. **/
@Serializable
data class PatchPollBody(
    val name: Retainable<String> = Retainable.retain, 
    val description: Retainable<Text> = Retainable.retain, 
    val choices: Retainable<List<String>> = Retainable.retain, 
    val startDate: Retainable<DateTime?> = Retainable.retain, 
    val endDate: Retainable<DateTime?> = Retainable.retain, 
)

/** The response for <see cref="M:PicMeApi.PollApis.PatchPoll(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,System.Nullable{SimpleDatabase.DataScope},SimpleDatabase.PollId,System.Text.Json.JsonDocument)" />. **/
@Serializable
data class PatchPollResponse(
    val poll: Poll = Poll(), 
)


@Serializable
data class Poll(
    val pollId: PollId = PollId(""), 
    val pollGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val creatorUserId: UserId = UserId(""), 
    val creationTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val modifierUserId: UserId = UserId(""), 
    val modificationTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val startDate: DateTime? = null, 
    val endDate: DateTime? = null, 
    val name: String = "", 
    val description: Text = Text(""), 
    val choices: List<String> = listOf(), 
)

/** A record containing the response after deleting a colleciton. **/
@Serializable
data class DeletePollResponse(
    val pollId: PollId = PollId(""), 
    val pollGlobalId: RecordGlobalId = RecordGlobalId(""), 
)

/** A record containing the structure of the body for the <see cref="M:PicMeApi.PollApis.CreatePoll(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,System.Nullable{SimpleDatabase.DataScope},PicMeApi.CreatePollBody)" /> API. **/
@Serializable
data class CreatePollBody(
    val name: String = "", 
    val description: Text = Text(""), 
    val choices: List<String> = listOf(), 
    val startDate: DateTime? = null, 
    val endDate: DateTime? = null, 
)

/** The response for <see cref="M:PicMeApi.PollApis.CreatePoll(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,System.Nullable{SimpleDatabase.DataScope},PicMeApi.CreatePollBody)" />. **/
@Serializable
data class CreatePollResponse(
    val pollId: PollId = PollId(""), 
)

/** A record that holds information about an upload query. **/
@Serializable
data class PollQuery(
    val creationTime: DateTimeRange? = null, 
    val creatorUserId: UserId? = null, 
    val modificationTime: DateTimeRange? = null, 
    val name: String? = null, 
    val description: Text? = null, 
    val isEmpty: Boolean = false, 
)

/** A record containing the response from the <see cref="M:PicMeApi.PollApis.ListPolls(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeApi.PollQuery,System.Int32,System.String,System.Nullable{SimpleDatabase.UserId})" /> API. **/
@Serializable
data class ListPollsResponse(
    val polls: List<ListedPoll> = listOf(), 
    val continuation: String? = null, 
)

/** A record containing the data for a poll returned in a list of polls. **/
@Serializable
data class ListedPoll(
    val poll: Poll = Poll(), 
    val userRights: Rights = Rights.values().first(), 
    val userParticipationRights: Rights = Rights.values().first(), 
)

