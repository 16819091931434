package com.picme.views

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.children
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.collection2.ListedCollection
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

class CollectionList : PicmeDialog() {
    private val showSearch = Property(false)
    override val title = Constant("Collections")
    override fun ViewWriter.topBar(close: suspend () -> Unit) = row {
        centered - button {
            h3 {
                ::content { title() }
            }
            onClick { showSearch set false; close() }
        }
        expanding - space {}
        centered - actionButtonNoText(
            displayIcon = PIcon.add,
            onClick = {
                showSearch set false
                createCollectionModal()
            }
        )
        centered - actionButtonNoText(displayIcon = PIcon.search) {
            showSearch set !showSearch()
        }
    }

    override fun ViewWriter.content() {
        expanding - collectionList(showSearch) { close() }
    }
}

fun ViewWriter.collectionList(showSearch: Writable<Boolean>, onCollectionSelect: suspend () -> Unit = {}) = col {
    val searchText = Property("")
    showSearch bind showSearch.withWrite { searchText set "" }

    val selectedTab = Property(SelectedTab.First)

    onlyWhen { showSearch() } - col {
        spacing = 0.5.rem
        padded - col {
            fieldTheme - row {
                spacing = 0.5.rem
                expanding - textField {
                    hint = "Search for a collection"
                    content bind searchText
                }
                centered - button {
                    spacing = 0.rem
                    icon { source = PIcon.close.copy(width = 1.rem, height = 1.rem) }
                    onClick {
                        showSearch set !showSearch()
                        searchText set ""
                    }
                }
            }
        }
    }
    tabSelect(selectedTab, "My Collections", "Shared With Me")

    val myCollectionsList = shared {
        session.awaitNotNull().collection2.listCollectionsLive().all().filter(::ownsCollection)
    }

    col {
        exists = false; ::exists { myCollectionsList().isEmpty() && searchText().isNotEmpty() }
        spacing = 12.rem
        space()
        centered - FadedForeground.onNext - subtext("No collections that match")
    }

    suspend fun clickCol(coll: Readable<ListedCollection>) {
        onCollectionSelect()
        CollectionState.clearFilters()
        val safeId = SafeIds.encode(coll().collection.collectionId.raw)
        val screen = if (session.awaitNotNull().isVerifiedAccount()) CollectionDetail(safeId)
        else PartialAuthUploadView(safeId)
        mainScreenNavigator.navigate(screen)
    }

    recycler(
        items = myCollectionsList,
        clickListItem = ::clickCol,
        setup = { recycler ->
            reactive {
                recycler?.exists = selectedTab() == SelectedTab.First && myCollectionsList().isNotEmpty()
            }

            launch {
                val currentC = currentCollection() ?: return@launch
                myCollectionsList().firstOrNull { it.collection.collectionId == currentC.collectionId }?.let {
                    selectedTab.value = SelectedTab.First
                }
            }
            reactive {
                searchText.value = ""
                if (selectedTab() == SelectedTab.First) {
                    val collections = myCollectionsList()
                    launch {
                        delay(16)
                        val currentC = currentCollection.value?.collectionId?.raw ?: return@launch
                        val currIdx = collections.indexOfFirst { it.collection.collectionId.raw == currentC }
                        recycler?.scrollToIndex(currIdx, align = Align.Center)
                    }
                }
            }
        }
    )

    val sharedWithMeCollections = shared {
        session.awaitNotNull().collection2.listCollectionsLive().all().filter { !ownsCollection(it) }
    }

    recycler(
        items = sharedWithMeCollections,
        clickListItem = ::clickCol,
        setup = { recycler ->
            reactive {
                recycler?.exists = selectedTab() == SelectedTab.Second && sharedWithMeCollections().isNotEmpty()
            }

            launch {
                val currentC = currentCollection() ?: return@launch
                sharedWithMeCollections().firstOrNull { it.collection.collectionId == currentC.collectionId }?.let {
                    selectedTab.value = SelectedTab.Second
                }
            }

            reactive {
                if (selectedTab() == SelectedTab.Second) {
                    val collections = sharedWithMeCollections()
                    launch {
                        delay(16)
                        val currentC = currentCollection.value?.collectionId?.raw ?: return@launch
                        val currIdx = collections.indexOfFirst { it.collection.collectionId.raw == currentC }
                        recycler?.scrollToIndex(currIdx, align = Align.Center)
                    }
                }
            }
        }
    )
}

fun ViewWriter.recycler(
    items: Readable<List<ListedCollection>>,
    clickListItem: suspend (Readable<ListedCollection>) -> Unit,
    setup: ViewWriter.(recycler1: RecyclerView?) -> Unit
) {
    var recycler: RecyclerView?
    expanding - recyclerView {
        recycler = this
        exists = false;
        children(items, id = { it.collection.collectionId }) { coll ->
            unpadded - expanding - row {
                spacing = 0.dp
                space(multiplier = 0.5)
                unpadded - expanding - stack {
                    button {
                        dynamicTheme {
                            if (coll().collection == currentCollection()) ThemeDerivation {
                                it.copy(background = Color.menuIconBg).withBack
                            }
                            else null
                        }
                        val weAreOwner = shared { ownsCollection(coll()) }
                        spacing = 0.25.rem
                        onClick {
                            clickListItem(coll)
                        }
                        row {
                            atTop - padded - stack {
                                spacing = 0.rem

                                centered - sizeConstraints(
                                    minWidth = 2.rem,
                                    minHeight = 2.rem
                                ) - unpadded - stack {
                                    sizeConstraints(height = 1.5.rem, width = 1.5.rem) - centered - icon {
                                        source = PIcon.image.copy(width = 1.rem, height = 1.rem)
                                        ::source {
                                            if (weAreOwner()) PIcon.image
                                            else PIcon.guests
                                        }
                                    }
                                }
                            }

                            expanding - centered - col {
                                spacing = 0.dp
                                h6 { ::content { coll().collection.name } }
                                FadedForeground.onNext - subtext {
                                    ::exists  { !weAreOwner() }
                                    ::content { coll().userParticipationRights.rightsOnCollection() }
                                }
                            }
                        }
                    }
                }
                space(multiplier = 0.5)
            }
        }
    }
    setup(recycler)
}