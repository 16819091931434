package com.picme.sdk2.generated.collection2

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


/** A struct that holds a PicMe upload id.  A collection id is required for this to uniquely identify a upload. **/
@Serializable
@JvmInline
value class UploadId(val raw: String = "")

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.GetDeletedUpload(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,PicMeModel.UploadId)" /> API. **/
@Serializable
data class GetDeletedUploadResponse2(
    val upload: Upload = Upload(), 
    val getThumbnailUri: Uri = "", 
    val getDetailsUri: Uri = "", 
)

/** A record that holds information about an upload (but not the binary data). **/
@Serializable
data class Upload(
    val uploadId: UploadId = UploadId(""), 
    val uploadGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val uploadTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val uploadState: UploadState = UploadState.values().first(), 
    val uploaderUserId: UserId = UserId(""), 
    val mimeType: MimeType = MimeType(""), 
    val bytes: Int64 = 0, 
    val originalFilename: String = "", 
    val identifiedHash: String = "", 
    val caption: Text = Text(""), 
    val notes: Text? = null, 
    val location: String = "", 
    val session: String = "", 
    val copiedFromCollectionId: CollectionId? = null, 
    val copiedFromUploadId: UploadId? = null, 
    val originalAvailability: UploadOriginalAvailability = UploadOriginalAvailability.values().first(), 
    val blockedReason: String? = null, 
    val width: Int32? = null, 
    val height: Int32? = null, 
)

/** An enumeration of possible upload states. **/
object UploadStateSerializer: KSerializer<UploadState> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("collection2.UploadState", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): UploadState {
        return UploadState.byValue[decoder.decodeInt()] ?: UploadState.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: UploadState) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(UploadStateSerializer::class)
enum class UploadState(val value: Int) {
/** The upload has been registered and is probably being uploaded. **/
    Uploading(0),
/** The upload has been uploaded and is ready for processing. **/
    Uploaded(1),
/** The upload has been processed and is ready for viewing. **/
    Processed(2),
/** There was an error processing the upload. **/
    Error(3),
/** The upload has been blocked due to moderation. **/
    Blocked(4),
/** The upload is being deleted. **/
    Deleting(-1),
    ;
    companion object {
        val byValue = UploadState.values().associateBy { it.value }
    }
}

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.ListLinksTo(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,SimpleDatabase.LinkRelationshipType,PicMeModel.CollectionId)" /> API. **/
@Serializable
data class ListLinksToResponse2(
    val links: List<Link> = listOf(), 
)

/** A record representing a two-way (but directed) link between two entities such as membership in a group, a follower, a friendship, or access to a collection.
            Links are always tracked bidirectionally, but might not necessarily imply any rights on one direction, being kept bidirectionally only for counting and cleanup purposes.
            Links are constructed, modified, and destroyed in a way that ensures eventual consistency and durability.
            Note that links are neither atmoic nor isolated, so it's possible to query and see only one side of the link, and the parts will be immediately visible to everyone at some point during the construction.
            Links are categorized by <see cref="T:SimpleDatabase.LinkRelationshipType" />, and the link type may indicate that indexes for a secondary record should be copied into the scope of the primary record.
            Links use <see cref="T:SimpleDatabase.RecordGlobalId" /> rather than the various specific IDs because the specific IDs are not always indexed globally, and the may not even be unique (they may only be unique within a specific scope, such as an UploadId within a Collection), but the <see cref="T:SimpleDatabase.RecordGlobalId" /> is always unique within the database. **/
@Serializable
data class Link(
    val relationshipType: LinkRelationshipType = LinkRelationshipType.values().first(), 
    val primary: RecordGlobalId = RecordGlobalId(""), 
    val secondary: RecordGlobalId? = null, 
    val relationship: LinkRelationship? = null, 
    val encodedPrimaryPath: FileSystemRelativeFilePath = FileSystemRelativeFilePath(""), 
    val encodedSecondaryPath: FileSystemRelativeFilePath = FileSystemRelativeFilePath(""), 
)

/** A record that contains a relative file path.  
            File paths *never* end with a folder separator character.
            File paths usually have an extension. **/
@Serializable
@JvmInline
value class FileSystemRelativeFilePath(val raw: String = "")

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.DeleteCollectionCoverPhoto(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,System.Net.IPAddress)" /> API. **/

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.GetUpload(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,PicMeModel.UploadId)" /> API. **/
@Serializable
data class GetUploadResponse2(
    val upload: Upload = Upload(), 
    val uploader: UploaderInfo = UploaderInfo(), 
    val getThumbnailUri: Uri = "", 
    val getViewUri: Uri = "", 
    val getDetailsUri: Uri = "", 
)

/** A record containing shareable information about the uploader. **/
@Serializable
data class UploaderInfo(
    val uploaderName: String = "", 
    val getUploaderProfilePicture: Uri? = null, 
)

/** A record containing the structure of the body for the <see cref="M:PicMeApi.CollectionApis.PatchUpload(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,System.String,System.Net.IPAddress,System.String,PicMeModel.CollectionId,PicMeModel.UploadId,System.Text.Json.JsonDocument)" /> API.
            Any parts of the structure may be left unspecified, which will cause the existing value to be retained. **/
@Serializable
data class PatchUploadBody(
    val caption: Retainable<String> = Retainable.retain, 
    val anonymous: Retainable<Boolean> = Retainable.retain, 
)

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.PatchUpload(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,System.String,System.Net.IPAddress,System.String,PicMeModel.CollectionId,PicMeModel.UploadId,System.Text.Json.JsonDocument)" /> API. **/
@Serializable
data class PatchUploadResponse2(
    val upload: Upload = Upload(), 
)

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.DeleteUpload(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,PicMeModel.UploadId)" /> API. **/
@Serializable
data class DeleteUploadResponse2(
    val collectionId: CollectionId = CollectionId(""), 
    val uploadId: UploadId = UploadId(""), 
)

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.CopyUpload(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,System.String,System.Net.IPAddress,System.String,System.String,PicMeModel.CollectionId,PicMeModel.UploadId,PicMeModel.CollectionId,System.Boolean,System.Boolean)" /> API. **/
@Serializable
data class CopyUploadResponse2(
    val destinationUpload: Upload = Upload(), 
    val getDestinationThumbnailUri: Uri = "", 
)

/** A record containing a timestamp indicating the last time the collection was modified. **/
@Serializable
data class GetCollectionModificationStampResponse2(
    val modificationStamp: DateTime? = null, 
)

/** A record containing a collection cover photo upload Uri. **/
@Serializable
data class PutCollectionCoverPhotoResponse(
    val putCoverPhotoUri: Uri = "", 
)

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.ListUploads(System.String,SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,PicMeModel.UploadCondition,PicMeModel.UploadQuery,System.String,System.Nullable{System.Int32})" /> API. **/
@Serializable
data class ListDeletedUploadsResponse2(
    val uploadsPerPage: Int32 = 0, 
    val uploads: List<ListedUpload> = listOf(), 
    val continuation: String? = null, 
)

/** A record containing data about an upload. **/
@Serializable
data class ListedUpload(
    val uploadId: UploadId = UploadId(""), 
    val mimeType: MimeType = MimeType(""), 
    val thumbnailUrl: Uri = "", 
)

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.RestoreDeletedUploads(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,PicMeModel.UploadId[])" /> API. **/
@Serializable
data class RestoreDeletedUploadsResponse2(
    val restoredUploadCount: Int32 = 0, 
)

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.ListUploadKeywords(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,System.String,System.String)" /> API. **/
@Serializable
data class ListUploadKeywordsResponse(
    val keywords: List<String> = listOf(), 
)

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.GetOrStartPartialZipOfOriginals(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,PicMeModel.UploadId[])" /> API. **/
@Serializable
data class GetOrStartPartialZipOfOriginalsResponse2(
    val getZipStatusUri: Uri = "", 
)

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.GetOrStartFullZipOfOriginals(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId)" /> API. **/
@Serializable
data class GetOrStartFullZipOfOriginalsResponse2(
    val getZipStatusUri: Uri = "", 
)

/** A struct that holds a PicMe collection sharing authorization code. **/
@Serializable
@JvmInline
value class CollectionSharingAuthorizationCode(val raw: String = "")

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.SharingConnect(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionSharingAuthorizationCode)" /> API. **/

/** A record containing the structure of the body for the <see cref="M:PicMeApi.CollectionApis.CreateUpload(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,System.String,System.Net.IPAddress,System.String,PicMeModel.CollectionId,PicMeApi.CreateUploadBody,System.Boolean)" /> API. **/
@Serializable
data class CreateUploadBody(
    val filename: String = "", 
    val contentType: MimeType = MimeType(""), 
    val bytes: Int64 = 0, 
    val identifiedHash: String = "", 
    val caption: String? = null, 
    val notes: String? = null, 
    val anonymous: Boolean = false, 
)

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.CreateUpload(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,System.String,System.Net.IPAddress,System.String,PicMeModel.CollectionId,PicMeApi.CreateUploadBody,System.Boolean)" /> API. **/
@Serializable
data class CreateUploadResponse2(
    val upload: Upload = Upload(), 
    val putOriginalUploadUri: Uri = "", 
    val getThumbnailUri: Uri = "", 
)

/** A Condition that can be used to query for uploads.  Supported conditions can be found in the <see cref="T:SimpleDatabase.ConditionType" /> enumeration.
            See https://github.com/lightningkite/lightning-server/blob/version-3/docs/use-as-client.md#rest-endpoints for more details and examples. **/
/** See Condition file for definition **/    

/** An enumeration of standard condition types.
            In addition, condition types can be property names. **/
object ConditionTypeSerializer: KSerializer<ConditionType> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("collection2.ConditionType", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): ConditionType {
        return ConditionType.byValue[decoder.decodeInt()] ?: ConditionType.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: ConditionType) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(ConditionTypeSerializer::class)
enum class ConditionType(val value: Int) {
/** Never let anything through. (Probably not useful except for testing) **/
    Never(0),
/** Lets everything through. **/
    Always(1),
/** Only lets things through if all the conditions in the array are met.
            Uses Subconditions. **/
    And(2),
/** Lets things through if any of the conditions in the array are met.
            Uses Subconditions. **/
    Or(3),
/** Only let through items that match the given value exactly.
            Uses a value. **/
    Equal(4),
/** Only let through items that do not match the given value exactly.
            Uses a value. **/
    NotEqual(5),
/** Only let through items that match one of the given values exactly.
            Uses an array of values. **/
    Inside(6),
/** Only let through items that do not match one of the given values exactly.
            Uses an array of values. **/
    NotInside(7),
/** Only let through items that are greater than the given value.
            Uses a sortable value. **/
    GreaterThan(8),
/** Only let through items that are less than the given value.
            Uses a sortable value. **/
    LessThan(9),
/** Only let through items that are greater than or equal to the given value.
            Uses a sortable value. **/
    GreaterThanOrEqual(10),
/** Only let through items that are less than or equal to the given value.
            Uses a sortable value. **/
    LessThanOrEqual(11),
/** Only let through items that contain the given string.
            Uses a string value. **/
    StringContains(12),
/** Only let through items that match a regular expression.
            Uses a regular expression string value. **/
    RegexMatches(13),
/** Only matches lists where every element matches the given condition. **/
    ListAllElements(14),
/** Only matches lists where at least one element matches the given condition. **/
    ListAnyElements(15),
/** Only matches lists where the size matches the given count.
            Uses a count value. **/
    ListSizesEqual(16),
/** Only matches sets where every element matches the given condition. **/
    SetAllElements(17),
/** Only matches sets where at least one element matches the given condition. **/
    SetAnyElements(18),
/** Only matches sets where the size matches the given count.
            Uses a count value. **/
    SetSizesEqual(19),
/** Only matches values that are not null. **/
    IfNotNull(20),
    ;
    companion object {
        val byValue = ConditionType.values().associateBy { it.value }
    }
}

/** A record that holds information about an upload query. **/
@Serializable
data class UploadQuery(
    val uploadTimeRange: DateTimeRange? = null, 
    val uploadState: UploadState? = null, 
    val uploaderUserId: UserId? = null, 
    val mimeType: MimeType? = null, 
    val bytesRange: Int64Range? = null, 
    val originalFilenameKeywords: String? = null, 
    val identifiedHash: String? = null, 
    val captionKeywords: Text? = null, 
    val copiedFromCollectionId: CollectionId? = null, 
    val originalAvailability: UploadOriginalAvailability? = null, 
    val blockedReasonKeywords: String? = null, 
    val isEmpty: Boolean = false, 
)

/** A struct that holds an Int64 range.  Represented as the lower-bound integer and the upper bound integer separated by two dashes. **/
@Serializable
@JvmInline
value class Int64Range(val raw: String = "")

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.ListUploads(System.String,SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,PicMeModel.UploadCondition,PicMeModel.UploadQuery,System.String,System.Nullable{System.Int32})" /> API. **/
@Serializable
data class ListUploadsResponse2(
    val uploadsPerPage: Int32 = 0, 
    val uploads: List<ListedUpload> = listOf(), 
    val continuation: String? = null, 
)

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.DeleteAllUploads(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId)" /> API. **/
@Serializable
data class DeleteAllUploadsResponse2(
    val collectionId: CollectionId = CollectionId(""), 
)

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.RevokeRights(CloudFileSystems.ICloudFileSystem,SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,PicMeModel.CollectionId,SimpleDatabase.UserId)" /> API. **/
@Serializable
data class RevokeRightsResponse2(
    val linksDeleted: Int32 = 0, 
)

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.ModifyRights(CloudFileSystems.ICloudFileSystem,SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,PicMeModel.CollectionId,SimpleDatabase.UserId,SimpleDatabase.Rights)" /> API. **/
@Serializable
data class ModifyRightsResponse(
    val linksModified: Int32 = 0, 
)

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.RequestConnect(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId)" /> API. **/

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.GetCollectionRights(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,System.Nullable{SimpleDatabase.UserId})" /> API. **/
@Serializable
data class GetCollectionRightsResponse2(
    val rights: Rights = Rights.values().first(), 
    val participationRights: Rights = Rights.values().first(), 
)

/** A record ctonaining the collection metadata for the specified collection. **/
@Serializable
data class GetCollectionResponse2(
    val collection: PCollection = PCollection(), 
    val getCoverPhotoUri: Uri = "", 
)

/** A record that contains the core (non-link but indexed, or security-related) data for a collection. **/
@Serializable
data class PCollection(
    val collectionId: CollectionId = CollectionId(""), 
    val collectionGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val sortKey: Tumbler? = null, 
    val created: DateTime = Instant.fromEpochMilliseconds(0), 
    val creatorUserId: UserId = UserId(""), 
    val modified: DateTime = Instant.fromEpochMilliseconds(0), 
    val name: String = "", 
    val type: String = "", 
    val message: String = "", 
    val coverPhotoVersionStamp: GenericIdentifier? = null, 
    val collectionPlan: CollectionPlan = CollectionPlan.values().first(), 
    val uploadStart: DateTime? = null, 
    val uploadEnd: DateTime? = null, 
)

/** An enumeration of the possible paid (or unpaid) collection plans, which may or may not supercede user plans depending on rules TBD. **/
object CollectionPlanSerializer: KSerializer<CollectionPlan> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("collection2.CollectionPlan", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): CollectionPlan {
        return CollectionPlan.byValue[decoder.decodeInt()] ?: CollectionPlan.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: CollectionPlan) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(CollectionPlanSerializer::class)
enum class CollectionPlan(val value: Int) {
/** The user has not paid for a plan and will be restricted to whatever the free plan allows, or whatever the user-level plan allows. **/
    None(0),
/** This collection is completely unlimited. **/
    Unlimited(1),
    ;
    companion object {
        val byValue = CollectionPlan.values().associateBy { it.value }
    }
}

/** A struct that holds a generic (GUID-type) identifier. **/
@Serializable
@JvmInline
value class GenericIdentifier(val raw: String = "")

/** A record containing the structure of the body for the <see cref="M:PicMeApi.CollectionApis.PatchCollection(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,System.Text.Json.JsonDocument)" /> API.
            Any parts of the structure may be left unspecified, which will cause the existing value to be retained. **/
@Serializable
data class PatchCollectionBody(
    val name: Retainable<String> = Retainable.retain, 
    val message: Retainable<String> = Retainable.retain, 
    val uploadStart: Retainable<DateTime?> = Retainable.retain, 
    val uploadEnd: Retainable<DateTime?> = Retainable.retain, 
)

/** A record containing a response from the <see cref="M:PicMeApi.CollectionApis.PatchCollection(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId,System.Text.Json.JsonDocument)" /> API. **/
@Serializable
data class PatchCollectionResponse2(
    val collection: PCollection = PCollection(), 
)

/** A record containing the response after deleting a colleciton. **/
@Serializable
data class DeleteCollectionResponse2(
    val collectionId: CollectionId = CollectionId(""), 
    val collectionGlobalId: RecordGlobalId = RecordGlobalId(""), 
)

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.ListUsers(SimpleDatabase.Database,SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionId)" /> API. **/
@Serializable
data class ListUsersResponse2(
    val users: List<CollectionUserData> = listOf(), 
)

/** A record containing information about a user who has access to a collection. **/
@Serializable
data class CollectionUserData(
    val userId: UserId = UserId(""), 
    val name: String = "", 
    val rights: Rights = Rights.values().first(), 
    val participationRights: Rights = Rights.values().first(), 
)

/** A record containing the structure of the body for the <see cref="M:PicMeApi.CollectionApis.CreateCollection(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeApi.CreateCollectionBody)" /> API. **/
@Serializable
data class CreateCollectionBody(
    val name: String = "", 
    val message: String? = null, 
    val uploadStart: DateTime? = null, 
    val uploadEnd: DateTime? = null, 
)

/** A record contianing a response from the <see cref="M:PicMeApi.CollectionApis.CreateCollection(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeApi.CreateCollectionBody)" /> API. **/
@Serializable
data class CreateCollectionResponse2(
    val collection: PCollection = PCollection(), 
)

/** A record that holds information about an upload query. **/
@Serializable
data class CollectionQuery(
    val creationTime: DateTimeRange? = null, 
    val creatorUserId: UserId? = null, 
    val modificationTime: DateTimeRange? = null, 
    val name: String? = null, 
    val type: String? = null, 
    val collectionPlan: CollectionPlan? = null, 
    val isEmpty: Boolean = false, 
)

/** A record containing the response from the <see cref="M:PicMeApi.CollectionApis.ListCollections(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,PicMeModel.CollectionQuery,System.Int32,System.String,System.Nullable{SimpleDatabase.UserId})" /> API. **/
@Serializable
data class ListCollectionsResponse2(
    val collections: List<ListedCollection> = listOf(), 
    val continuation: String? = null, 
)

/** A record containing the data for a collection returned in a list of collections. **/
@Serializable
data class ListedCollection(
    val collection: PCollection = PCollection(), 
    val getCoverPhotoUri: Uri = "", 
    val userRights: Rights = Rights.values().first(), 
    val userParticipationRights: Rights = Rights.values().first(), 
)

/** An enumeration indicating the availability of the original version of the upload. **/
object UploadOriginalAvailabilitySerializer: KSerializer<UploadOriginalAvailability> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("collection2.UploadOriginalAvailability", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): UploadOriginalAvailability {
        return UploadOriginalAvailability.byValue[decoder.decodeInt()] ?: UploadOriginalAvailability.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: UploadOriginalAvailability) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(UploadOriginalAvailabilitySerializer::class)
enum class UploadOriginalAvailability(val value: Int) {
/** The upload original is locked, so the detail-view version of the upload will be limited in fidelity 
            unless the user or collection plan indicates that upload-by-upload unlocking is not required. **/
    Locked(0),
/** The upload has been unlocked, so the full-fidelity version of the original is available. **/
    Unlocked(1),
    ;
    companion object {
        val byValue = UploadOriginalAvailability.values().associateBy { it.value }
    }
}

