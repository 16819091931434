package com.picme

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.exceptions.ExceptionHandler
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.navigatorView
import com.lightningkite.kiteui.views.l2.navigatorViewDialog
import com.lightningkite.kiteui.views.l2.overlayStack
import com.picme.components.*
import com.picme.layout.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.Session
import com.picme.sdk2.generated.collection2.PatchCollectionBody
import com.picme.sdk2.toSafeEncoded
import com.picme.sdk2.unauthApi
import com.picme.views.*
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.launch

interface UnauthScreen
interface IncludeTopBar
interface NoTopBar


private val loadingInitial = Property(true)

val requireVerifiedAccounts = Platform.current != Platform.iOS && Platform.current != Platform.Android

fun ViewWriter.app(main: ScreenNavigator, dialog: ScreenNavigator) {
    prepareModelsApps()
    reactive {
        val color = ((dialog.currentScreen() ?: main.currentScreen()) as? ControlsStatusBarColor)?.statusBarColor?.invoke()
            ?: Color.white
        setStatusBarColor(color)
    }
    appMaterialTheme.onNext - customAppBase(main, dialog) {
        this += object : ExceptionHandler {
            override val priority: Float
                get() = 10f

            override fun handle(view: RView, working: Boolean, exception: Exception): (() -> Unit) {
                if (exception is ConnectionException && exception.cause is CancellationException) return {}
                else {
                    exception.report()
                    throw Error(exception)
                }
            }
        }
        reactiveSuspending {
            try {
                val token = sessionRefreshToken()
                if (token != null) {
                    val res = unauthApi.awaitOnce().authenticationHandler.refreshUserAuthentication(token)
                    val newSession = Session(
                        unauthApi.awaitOnce(),
                        res.successfulAuthentication!!.authenticated,
                        res.successfulAuthentication.authenticatedUser
                    )
                    newSession.collection2.listCollectionsLive().all.awaitOnce()
                    session set newSession
                } else {
                    session set null
                }
            } catch (e: Exception) {
                e.printStackTrace2()
                session set null
                logout()
            } finally {
                loadingInitial.set(false)
            }
        }
        reactiveSuspending {
            if (loadingInitial()) return@reactiveSuspending
            val s = screenNavigator.currentScreen()

            val notVerified =
                (session() == null || (requireVerifiedAccounts && session()?.isVerifiedAccount() == false))

            if (s !is UnauthScreen && notVerified) {
                screenNavigator.navigate(
                    if (requireVerifiedAccounts) LoginOrSignUp() else CollectionLanding
                )
            }
            if (s is LoginOrSignUp && session()?.isVerifiedAccount() == true) {
                navigateToCollOrLanding()
            }
        }

        reactiveScope {
            if (logoutTrigger()) {
                launchGlobal {
                    removeCaches()
                    session.value = null
                    sessionRefreshToken set null
                    currentCollection set null
                    acceptingInvite set null
                    dialogScreenNavigator.dismiss()
                    screenNavigator.reset(LoginOrSignUp())
                    logoutTrigger set false
                }
            }
        }

        fullScreenLoading(loadingInitial)

        col {
            ::exists { !loadingInitial() }
            spacing = 0.px
            expanding - row {
                spacing = 1.dp
                sidePanel()
                expanding - col {
                    spacing = 0.dp
                    toolbar()
                    expanding - navigatorView(this.screenNavigator)
                }
            }
        }
    }
}


fun ViewWriter.customAppBase(main: ScreenNavigator, dialog: ScreenNavigator, mainLayout: ContainingView.() -> Unit) {
    stack {
        spacing = 0.dp
        mainScreenNavigator = main
        dialog.let {
            dialogScreenNavigator = it
        }
        main.bindToPlatform(context)
        screenNavigator = main
        overlayStack = this
        mainLayout()
        dialog.let {
            ThemeDerivation { it.withoutBack }.onNext - navigatorViewDialog()
        }
        toast()
    }
}

interface ControlsStatusBarColor {
    val statusBarColor: Readable<Color> get() = Constant(appMaterialTheme.background.closestColor())
}

enum class ActionSection { None, Collection, Trash, }

suspend fun includeTopBar(curr: Page?): Boolean {
    if (curr is NoTopBar) return false
    if (curr is IncludeTopBar) return true

    if (curr is CollectionLanding) return (session()?.isVerifiedAccount() == true)

    return (curr !is UnauthScreen && curr !is PicmeDialog)
}

val actionSection = shared {
    if (isSmallScreen()) ActionSection.None
    else if (currentCollection() == null) ActionSection.None
    else if (CollectionState.currView() == CollSubPage.Trash) ActionSection.Trash
    else ActionSection.Collection
}



private fun ViewWriter.toolbar() {
    val showToolbar = sharedSuspending {
        includeTopBar(screenNavigator.currentScreen())
    }

    bar - row {
        ::exists { showToolbar() && currentCollection() != null }
        dynamicTheme {
            if (CollectionState.currView() != CollSubPage.Main) PicmeAuthGreyBackgroundSemantic
            else null
        }
        spacing = 0.75.rem
        collectionListMenu()


        val title = shared { screenNavigator.currentScreen()?.title?.await() ?: "..." }


        expanding - stack {
            atStart - button {
                themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.Constant(1.rem)).withoutBack }
                h4 {
                    ::exists { title().isNotEmpty() }
                    ::content { title() }
                }
                onClick {
                    if (CollectionState.currView() == CollSubPage.Main && currentCollection()?.let { ownsPCollection(it) } == true) {
                        editTitleDialog()
                    } else {
                        CollectionState.setView(CollSubPage.Main)
                    }

                }
            }
        }

        centered - stack {
            ::exists{ title().isEmpty() }
            picmeIconDisplay()
        }
        unpadded - stack {
            val exists = sharedSuspending {
                includeTopBar(screenNavigator.currentScreen()) && actionSection() != ActionSection.None
            }
            ::exists { exists() }
            spacing = 0.rem

            swapView {
                swapping(
                    current = { actionSection() },
                    views = {
                        when (it) {
                            ActionSection.Collection -> collectionButtonsTop()
                            ActionSection.Trash -> trashActions(false)
                            else -> {}
                        }
                    },
                )
            }
        }
        atEnd - userInfoPopover()
    }


    bar - row {
        ::exists { showToolbar() && currentCollection() == null }
        spacing = 0.75.rem
        collectionListMenu()

        expanding - space { }
        centered - stack {
            picmeIconDisplay()
        }

        expanding - space { }
        userInfoPopover()
    }
    separatorTheme - separator { ::exists { showToolbar() } }
}


private fun ViewWriter.editTitleDialog() {
    val collName = Property("")
    launch {
        collName set (currentCollection()?.name ?: "")
    }
    dialogGeneric { close ->
        spacing = 2.rem
        col {
            spacing = 0.05.rem
            h4 {
                spacing = 0.dp
                content = "Collection Name"
                align = Align.Center
            }
            styledTextField {
                spacing = 0.dp
                content bind collName
                hint = "Name"
            }
            actionOrCancelSection(
                onCancel = close,
                actionButton = {
                    importantButton("Save", enabled = shared { collName().isNotEmpty() }) {
                        currentCollection()?.collectionId?.let { id ->
                            session.awaitNotNull().collection2.patchCollection(
                                collectionId = id,
                                body = PatchCollectionBody(
                                    name = Retainable(collName()),
                                )
                            )
                        }
                        close()
                    }
                },
            )
        }
    }
}