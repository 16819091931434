package com.picme.sdk2.generated.qrgeneration

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object QrGenerationHandlerEndpointsSerializer : KSerializer<QrGenerationHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("QrGenerationHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: QrGenerationHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): QrGenerationHandlerEndpoints {
        val string = decoder.decodeString()
        return QrGenerationHandlerEndpoints(string)
    }
}


@Serializable(with = QrGenerationHandlerEndpointsSerializer::class)
data class QrGenerationHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Deprecated.  Generates a QR code from the specified parameters.  Currently not authenticated, but the TLD must be picme.com or localhost, so it shouldn't be particularly useful for third parties. **/
    suspend fun generateQrCodePng(
        hostnamePrefix: String,
        pathAndQueryStringPattern: String,
        useLogo: Boolean,
        colorLogo: Boolean,
        pixels: Int32,
        accessToken: suspend () -> String? = { null }
    ): CreateQrCodeImageResponse = fetch(
        url = "$url/pngqr64/${hostnamePrefix.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "pathAndQueryStringPattern" to pathAndQueryStringPattern.toParamString(),
            "useLogo" to useLogo.toParamString(),
            "colorLogo" to colorLogo.toParamString(),
            "pixels" to pixels.toParamString(),
        ).filter { it.value != "null" },
    )
/** Generates a QR code with a logo from the specified parameters.  Currently not authenticated, but the TLD must be picme.com or localhost, so it shouldn't be particularly useful for third parties. **/
    suspend fun generateQrCodeSvg(
        hostnamePrefix: String,
        pathAndQueryStringPattern: String,
        useLogo: Boolean,
        colorLogo: Boolean,
        accessToken: suspend () -> String? = { null }
    ): CreateQrCodeImageResponse = fetch(
        url = "$url/qr64/${hostnamePrefix.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "pathAndQueryStringPattern" to pathAndQueryStringPattern.toParamString(),
            "useLogo" to useLogo.toParamString(),
            "colorLogo" to colorLogo.toParamString(),
        ).filter { it.value != "null" },
    )
}

