package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.PIcon
import com.picme.picmeDanger
import com.picme.picmeToastBg
import com.picme.picmeToastGreen

fun ViewWriter.toast() = atTopCenter - padded - stack {
    ::exists { currentToast() != null }
    card - themeFromLast {
        it.copy(
            foreground = Color.white,
            background = Color.picmeToastBg,
            outlineWidth = 0.px
        )
    } - stack {
        row {
            onlyWhen { currentToast()?.type == ToastType.Success } - centered - icon {
                themeChoice += ThemeDerivation {
                    it.copy(
                        foreground = Color.picmeToastGreen,
                        iconOverride = Color.picmeToastGreen,
                        cornerRadii = CornerRadii.ForceConstant(2.rem)
                    ).withoutBack
                }
                spacing = 0.rem
                source = PIcon.select
            }
            onlyWhen { currentToast()?.type == ToastType.Error } - centered - icon {
                themeChoice += ThemeDerivation {
                    it.copy(
                        foreground = Color.picmeDanger,
                        iconOverride = Color.picmeDanger,
                        cornerRadii = CornerRadii.ForceConstant(2.rem)
                    ).withoutBack
                }
                spacing = 0.rem
                source = PIcon.close
            }
            centered - col {
                spacing = 0.rem
                h6 {
                    ::content { currentToast()?.messagePrimary ?: "..." }
                }
                text {
                    ::exists { currentToast()?.messageSecondary != null }
                    ::content {
                        currentToast()?.messageSecondary ?: "..."
                    }
                }
            }
            col {
                button {
                    spacing = 0.rem
                    icon {
                        themeChoice += ThemeDerivation {
                            it.copy(
                                foreground = Color.gray,
                                iconOverride = Color.gray,
                            ).withoutBack
                        }
                        source = PIcon.close.copy(width = 2.rem, height = 2.rem)
                    }
                    onClick {
                        currentToast set null
                    }
                }
            }
        }
    }
}

enum class ToastType { Success, Error }

data class CurrentToast(
    val messagePrimary: String,
    val messageSecondary: String? = null,
    val type: ToastType = ToastType.Success
)


val currentToast = Property<CurrentToast?>(null)
