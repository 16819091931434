package com.picme.components

import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.usesTouchscreen
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.RecyclerViewPagingPlacer
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.kiteui.views.l2.childrenMultipleTypes
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.kiteui.views.withoutAnimation
import com.lightningkite.now
import com.picme.Resources
import com.picme.actuals.advertisement
import kotlin.time.Duration.Companion.minutes

fun ViewWriter.viewPagerWithAds(
    imagesWithAds: Readable<List<ImageDisplayInfo>>,
    incomingImage: Property<RecyclableInfo>,
) {
    var startingIndex = -1
    unpadded - weight(1f) - horizontalRecyclerView {

        placer = RecyclerViewPagingPlacer()
        snapToElements = Align.Center
        scrollSnapStop = true

        with(outerStack) {
            if (!Platform.usesTouchscreen) {
                gravity(Align.Start, Align.Center) - button {
                    icon(Icon.chevronLeft.copy(width = 3.rem, height = 3.rem), "Previous")
                    onClick { centerIndex set centerIndex() - 1 }
                }
                gravity(Align.End, Align.Center) - button {
                    icon(Icon.chevronRight.copy(width = 3.rem, height = 3.rem), "Next")
                    onClick { centerIndex set centerIndex() + 1 }
                }
            }
        }

        reactiveSuspending {
            if (startingIndex == -1) {
                startingIndex = imagesWithAds().map { it.id }.indexOf(incomingImage().id)
                centerIndex.set(startingIndex)
            }
        }
        reactiveSuspending {
            val updatedIndex = centerIndex()
            val image = imagesWithAds().getOrNull(updatedIndex)
            image?.let {
                incomingImage.set(
                    RecyclableInfo(
                        id = image.id,
                        thumbnail = image.thumbnail,
                        isAd = image.type == ImageDisplayInfoType.Ad,
                        mimeType = image.mimeType
                    )
                )
            }
        }
        childrenMultipleTypes(imagesWithAds, id = ImageDisplayInfo::id) {
            elementsMatching { it.type == ImageDisplayInfoType.Ad } renderedAs { data ->
                stack {
                    advertisement {
                        fullSizeAd = true
                        adUnitId = when (Platform.current) {
//                                Platform.Android -> "ca-app-pub-6829519054466679/5376859530"
                            Platform.Android -> "ca-app-pub-3940256099942544/6300978111" // test adUnitId
                            Platform.iOS -> "ca-app-pub-6829519054466679/9487311157"
                            else -> "ca-app-pub-3940256099942544/2435281174" // Test adUnitId
                        }
                        adImgSrc = Resources.picMePaigeAdVertical
                    }
                }
            }
            elementsMatching { it.type == ImageDisplayInfoType.Video } renderedAs { data ->
                stack {
                    video {
                        ::source { data().viewVideo() }
                        showControls = true
                    }
                }
            }
            elementsMatching { it.type == ImageDisplayInfoType.Image } renderedAs { data ->
                stack {
                    val img = zoomableImage {
                        showLoadingIndicator = false
                        this.reactiveScope {
                            source = data().thumbnail
                            data().viewImage()?.let { source = it }
                        }
                        scaleType = ImageScaleType.Fit
                    }
                }
            }
        }
    }
}


data class ImageDisplayInfo(
    val id: String,
    val type: ImageDisplayInfoType,
    val thumbnail: ImageSource,
    val originalImage: Readable<ImageSource?> = Constant(null),
    val viewImage: Readable<ImageSource?> = Constant(null),
    val originalVideo: Readable<VideoSource?> = Constant(null),
    val viewVideo: Readable<VideoSource?> = Constant(null),
    val mimeType: String
)

enum class ImageDisplayInfoType { Ad, Video, Image }
