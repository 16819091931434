package com.picme.sdk2.generated.sso

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


/** A record containing the response from <see cref="M:PicMeApi.SsoApis.ContinueGoogleAuthentication(SimpleDatabase.Auth,Security.ExternalEncryptionKeyManager,System.Uri,System.String,System.String,System.Boolean,System.Net.IPAddress,System.String)" />. **/
@Serializable
data class ContinueGoogleAuthenticationResponse(
    val successfulAuthentication: AuthenticationComplete? = null, 
    val moreAuthenticationRequired: MoreAuthenticationRequired? = null, 
)


@Serializable
data class GoogleAuthConfig(
    val googleLoginEndpoint: String? = null, 
    val googleTokenExchangeEndpoint: String? = null, 
    val googleJwtValidationEndpoint: String? = null, 
    val googleClientId: String = "", 
    val googleClientSecret: String = "", 
    val googleProjectId: String? = null, 
    val endpoints: List<SsoAuthCallbackEndpoint> = listOf(), 
)


@Serializable
data class SsoAuthCallbackEndpoint(
    val endpointId: String = "", 
    val endpointUri: String = "", 
)

/** A record containing the response to a request to set the Google authentication configuration. **/

/** A record containing the response to a request to get the Google authentication configuration. **/
@Serializable
data class GetGoogleAuthenticationConfigResponse(
    val googleClientId: String = "", 
    val endpoints: List<SsoAuthCallbackEndpoint> = listOf(), 
)

/** A record containing the response from <see cref="M:PicMeApi.SsoApis.ContinueAppleAuthentication(SimpleDatabase.Auth,Security.ExternalEncryptionKeyManager,System.Uri,System.String,System.String,System.Boolean,System.Net.IPAddress,System.String)" />. **/
@Serializable
data class ContinueAppleAuthenticationResponse(
    val successfulAuthentication: AuthenticationComplete? = null, 
    val moreAuthenticationRequired: MoreAuthenticationRequired? = null, 
)


@Serializable
data class AppleAuthConfig(
    val appleLoginEndpoint: String? = null, 
    val appleTokenExchangeEndpoint: String? = null, 
    val appleJwtValidationEndpoint: String? = null, 
    val appleServiceId: String = "", 
    val appleTeamId: String = "", 
    val appleKeyId: String = "", 
    val appleKey: String = "", 
    val endpoints: List<SsoAuthCallbackEndpoint> = listOf(), 
)

/** A record containing the response to a request to set the Apple authentication configuration. **/

/** A record containing the response to a request to get the Apple authentication configuration. **/
@Serializable
data class GetAppleAuthenticationConfigResponse(
    val appleServiceId: String = "", 
    val endpoints: List<SsoAuthCallbackEndpoint> = listOf(), 
)

