package com.picme.sdk2.generated.discussion

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object DiscussionHandlerEndpointsSerializer : KSerializer<DiscussionHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("DiscussionHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: DiscussionHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): DiscussionHandlerEndpoints {
        val string = decoder.decodeString()
        return DiscussionHandlerEndpoints(string)
    }
}


@Serializable(with = DiscussionHandlerEndpointsSerializer::class)
data class DiscussionHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Patches a previously-posted discussion entry. **/
    suspend fun patchDiscussionEntry(
        discussionGlobalId: RecordGlobalId,
        discussionEntryId: Tumbler,
        body: PatchDiscussionEntryBody,
        discussionId: String,
        accessToken: suspend () -> String? = { null }
    ): PatchDiscussionEntryResponse = fetch(
        url = "$url/discussion-entry/${discussionId.toParamString()}/${discussionEntryId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
            "discussionGlobalId" to discussionGlobalId.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )
/** Deletes a discussion entry. **/
    suspend fun deleteDiscussionEntry(
        discussionGlobalId: RecordGlobalId,
        discussionEntryId: Tumbler,
        discussionId: String,
        accessToken: suspend () -> String? = { null }
    ): DeleteDiscussionEntryResponse = fetch(
        url = "$url/discussion-entry/${discussionId.toParamString()}/${discussionEntryId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
            "discussionGlobalId" to discussionGlobalId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Creates a discussion entry. **/
    suspend fun createDiscussionEntry(
        discussionGlobalId: RecordGlobalId,
        body: CreateDiscussionEntryBody,
        discussionId: String,
        accessToken: suspend () -> String? = { null }
    ): CreateDiscussionEntryResponse = fetch(
        url = "$url/discussion-entry/${discussionId.toParamString()}",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "discussionGlobalId" to discussionGlobalId.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )
/** Lists discussion entries in the specified discussion. **/
    suspend fun listDiscussionEntries(
        discussionGlobalId: RecordGlobalId,
        continuation: String?,
        discussionId: String,
        accessToken: suspend () -> String? = { null }
    ): ListDiscussionEntriesResponse = fetch(
        url = "$url/discussion-entry/${discussionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "discussionGlobalId" to discussionGlobalId.toParamString(),
            "continuation" to continuation.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun patchDiscussion(
        scope: DataScope?,
        discussionId: DiscussionId,
        body: PatchDiscussionBody,
        accessToken: suspend () -> String? = { null }
    ): PatchDiscussionResponse = fetch(
        url = "$url/discussion/${discussionId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
            "scope" to scope.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )

    suspend fun getDiscussion(
        scope: DataScope?,
        discussionId: DiscussionId,
        accessToken: suspend () -> String? = { null }
    ): GetDiscussionResponse = fetch(
        url = "$url/discussion/${discussionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "scope" to scope.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun deleteDiscussion(
        scope: DataScope?,
        discussionId: DiscussionId,
        accessToken: suspend () -> String? = { null }
    ): DeleteDiscussionResponse = fetch(
        url = "$url/discussion/${discussionId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
            "scope" to scope.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun createDiscussion(
        scope: DataScope?,
        body: CreateDiscussionBody,
        accessToken: suspend () -> String? = { null }
    ): CreateDiscussionResponse = fetch(
        url = "$url/discussion",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "scope" to scope.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )

    suspend fun listDiscussions(
        filterQuery: DiscussionQuery?,
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
        accessToken: suspend () -> String? = { null }
    ): ListDiscussionsResponse = fetch(
        url = "$url/discussion",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "filterQuery" to filterQuery.toParamString(),
            "itemsPerPage" to itemsPerPage.toParamString(),
            "continuation" to continuation.toParamString(),
            "forUserId" to forUserId.toParamString(),
        ).filter { it.value != "null" },
    )
}

