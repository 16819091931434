package com.picme.sdk2.generated.sso

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*
import com.picme.views.selectedBackend


object SsoHandlerEndpointsSerializer : KSerializer<SsoHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("SsoHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: SsoHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): SsoHandlerEndpoints {
        val string = decoder.decodeString()
        return SsoHandlerEndpoints(string)
    }
}


@Serializable(with = SsoHandlerEndpointsSerializer::class)
data class SsoHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Receives the Google Ouath credentials from Google.  This is usually an intermediate callback that doesn't need to be called by the frontend when using the standard setup. **/
    suspend fun intermediateGoogleAuthentication(
        code: String,
        state: String,
        setTosRead: Boolean,
        accessToken: suspend () -> String? = { null }
    ): RedirectResponse = fetch(
        url = "$url/sso/google/callback",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "code" to code.toParamString(),
            "state" to state.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
        ).filter { it.value != "null" },
    )
/** Receives the Apple Ouath credentials from Apple.  This is usually an intermediate callback that doesn't need to be called by the frontend when using the standard setup. **/
    suspend fun intermediateAppleAuthentication(
        code: String,
        state: String,
        setTosRead: Boolean,
        accessToken: suspend () -> String? = { null }
    ): RedirectResponse = fetch(
        url = "$url/sso/apple/callback",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "code" to code.toParamString(),
            "state" to state.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
        ).filter { it.value != "null" },
    )
/** Processes a JWT token received from Google, using that to complete the Google login process. **/
    suspend fun continueGoogleAuthentication(
        jwtToken: String,
        audience: String?,
        setTosRead: Boolean,
        accessToken: suspend () -> String? = { null }
    ): ContinueGoogleAuthenticationResponse = fetch(
        url = "$url/sso/google/jwt",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "jwtToken" to jwtToken.toParamString(),
            "audience" to audience.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
        ).filter { it.value != "null" },
    )
/** Updates the Google OAuth configuration. **/
    suspend fun updateGoogleAuthenticationConfig(
        config: GoogleAuthConfig,
        configName: String?,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/config/google",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "config" to config.toParamString(),
            "configName" to configName.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the Google OAuth configuration needed by the client.
            If this function returns a code of "NotFound", the client should assume that Google authentication is not available and should not display the option. **/
    suspend fun getGoogleAuthenticationConfig(
        configName: String?,
        accessToken: suspend () -> String? = { null }
    ): GetGoogleAuthenticationConfigResponse = fetch(
        url = "$url/config/google",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "configName" to configName.toParamString(),
        ).filter { it.value != "null" },
    )
/** Processes a JWT token received from Apple, using that to complete the Apple login process. **/
    suspend fun continueAppleAuthentication(
        jwtToken: String,
        audience: String?,
        setTosRead: Boolean,
        accessToken: suspend () -> String? = { null }
    ): ContinueAppleAuthenticationResponse = fetch(
        url = "${selectedBackend.value}/a/l/sso/apple/jwt",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "jwtToken" to jwtToken.toParamString(),
            "audience" to audience.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
        ).filter { it.value != "null" },
    )
/** Updates the Apple OAuth configuration. **/
    suspend fun updateAppleAuthenticationConfig(
        config: AppleAuthConfig,
        configName: String?,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/config/apple",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "config" to config.toParamString(),
            "configName" to configName.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the Apple OAuth configuration needed by the client.
            If this function returns a code of "NotFound", the client should assume that Apple authentication is not available and should not display the option. **/
    suspend fun getAppleAuthenticationConfig(
        configName: String?,
        accessToken: suspend () -> String? = { null }
    ): GetAppleAuthenticationConfigResponse = fetch(
        url = "$url/config/apple",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "configName" to configName.toParamString(),
        ).filter { it.value != "null" },
    )
/** Kicks off a Google OAuth login by redirecting to the Google OAuth login page.  
            When the login is complete, the browser will be redirected back to the <see cref="T:SimpleDatabase.SsoAuthCallbackEndpoint" /> corresponding to <paramref name="responseUriId" />,
            with the parameters "access", "refresh", "type", and "expires" appended to the query string.
            If there is an error during the authentication process, the browser will be redirected back to the <see cref="T:SimpleDatabase.SsoAuthCallbackEndpoint" /> corresponding to the "Error:" prefixed <paramref name="responseUriId" /> if it exists, or the non-prefixed (success) endpoint if it does not exist.
            Error conditions (to either endpoint) will have the parameters "error" and "message" appended to the query string. **/
    suspend fun startGoogleAuthentication(
        configName: String?,
        responseUriId: String,
        setTosRead: Boolean,
        accessToken: suspend () -> String? = { null }
    ): RedirectResponse = fetch(
        url = "$url/sso/google",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "configName" to configName.toParamString(),
            "responseUriId" to responseUriId.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
        ).filter { it.value != "null" },
    )
/** Kicks off a Apple OAuth login by redirecting to the Apple OAuth login page.  
            When the login is complete, the browser will be redirected back to the <see cref="T:SimpleDatabase.SsoAuthCallbackEndpoint" /> corresponding to <paramref name="responseUriId" />,
            with the parameters "access", "refresh", "type", and "expires" appended to the query string.
            If there is an error during the authentication process, the browser will be redirected back to the <see cref="T:SimpleDatabase.SsoAuthCallbackEndpoint" /> corresponding to the "Error:" prefixed <paramref name="responseUriId" /> if it exists, or the non-prefixed (success) endpoint if it does not exist.
            Error conditions (to either endpoint) will have the parameters "error" and "message" appended to the query string. **/
    suspend fun startAppleAuthentication(
        configName: String?,
        responseUriId: String,
        setTosRead: Boolean,
        accessToken: suspend () -> String? = { null }
    ): RedirectResponse = fetch(
        url = "$url/sso/apple",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "configName" to configName.toParamString(),
            "responseUriId" to responseUriId.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
        ).filter { it.value != "null" },
    )
}

